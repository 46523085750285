import axios from 'axios'

// let baseURL = ''
// if (
//   import.meta.env.MODE === 'production' ||
//   import.meta.env.MODE === 'staging'
// ) {
//   baseURL = import.meta.env.VITE_API_URL
// }
axios.defaults.baseURL = import.meta.env.VITE_API_URL

const platform = 'Web'

const instanceAxios = axios.create({
  // baseURL: baseURL,
  timeout: import.meta.env.VITE_API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': import.meta.env.VITE_API_KEY ?? '1ab2c3d4e5f61ab2c3d4e5f6',
    'x-language-code': import.meta.env.VITE_LANGUAGE_CODE ?? 'id_ID',
    'token-type': platform,
  },
})

const tokenType = localStorage.getItem('gromura.tokenType') || 'Bearer'
const accessToken = localStorage.getItem('gromura.accessToken') || null
if (accessToken !== null) {
  instanceAxios.defaults.headers.common[
    'Authorization'
  ] = `${tokenType} ${accessToken}`
} else {
  delete instanceAxios.defaults.headers.common['Authorization']
}

instanceAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response, config } = error
    if (typeof response !== 'undefined' && response.hasOwnProperty('status')) {
      if (response.status === 401) {
        const refreshToken =
          localStorage.getItem('gromura.refreshToken') || null

        if (refreshToken !== null) {
          return instanceAxios
            .post(
              `${routePathAPI()}/auth/refresh-token`,
              { refresh_token: refreshToken },
              {
                ...config,
              }
            )
            .then((response) => {
              const responseData = response.data
              if (response.status === 200 || responseData.statusCode === 200) {
                // SET NEW TOKEN
                localStorage.setItem(
                  'gromura.tokenType',
                  responseData.token_type
                )
                localStorage.setItem(
                  'gromura.accessToken',
                  responseData.access_token
                )

                // APPEND TOKEN TO CURRENT HEADER BEARER
                instanceAxios.defaults.headers.common[
                  'Authorization'
                ] = `${responseData.token_type} ${responseData.access_token}`

                // RETRYING REQUEST
                return instanceAxios(config)
              } else {
                return Promise.reject(response)
              }
            })
            .catch(() => {
              return Promise.reject(error)
            })
        }
      }
    }
    throw error
  }
)

export function routePathAPI() {
  // if (
  //   import.meta.env.MODE === 'production' ||
  //   import.meta.env.MODE === 'staging'
  // ) {
  //   return '/'
  // } else {
  //   return '/api/'
  // }
  return '/v1'
}
export function routePathAPIVersion() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return `/${import.meta.env.VITE_API_VERSION}/`
  } else {
    return `/api/${import.meta.env.VITE_API_VERSION}/`
  }
}

export default instanceAxios
