import { createI18n, NumberFormat } from 'vue-i18n'

import en from './../locales/en'
import id from './../locales/id'

const i18n = createI18n({
  legacy: false,
  locale: 'id',
  globalInjection: true,
  // allowComposition: true, // you need to specify that!
  messages: {
    en,
    id,
  },
  numberFormats: {
    id: {
      currency: {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
        maximumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
        maximumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
})

export default i18n
