import { createApp } from 'vue'
import { createPinia } from 'pinia'
import store from '@/store/vuex'

import App from './App.vue'
import i18n from './plugins/i18n.js'
import axios from './plugins/axios.js'
import utils from './utils'

import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes.js'
import { LANGUAGES } from './constants'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/free-mode'
import 'swiper/css/lazy'
import 'swiper/css/mousewheel'
import 'animate.css'

import { VueEternalLoading } from '@ts-pro/vue-eternal-loading'

import { Sheet } from 'bottom-sheet-vue3'

import Shimmer from 'vue3-shimmer'
import 'bottom-sheet-vue3/css/style.css'

import { configure } from 'vee-validate'
import VOtpInput from 'vue3-otp-input'
import VueLoadImage from 'vue-load-image'
// import { dragscrollNext } from 'vue-dragscroll'

import VueCountdown from '@chenfengyuan/vue-countdown'

import VueClipboard from 'vue3-clipboard'

import Vuesax from 'vuesax3'

// import VueGtag, { trackRouter } from 'vue-gtag-next'

import 'vuesax3/dist/vuesax.css'
import '@vueform/multiselect/themes/default.css'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

// import 'swiper/css/scrollbar'
import './assets/css/tailwind.css'
import './assets/scss/main.scss'

import { colors } from './themeConfig'

const app = createApp(App)
dayjs.extend(utc)
dayjs.extend(timezone)

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.query.lang
  if (!LANGUAGES.includes(language)) {
    language = LANGUAGES[0]
  }
  const { locale } = i18n.global
  locale.value = language

  // let requiresAuth = to.meta.hasOwnProperty('requiresAuth')
  //   ? to.meta.requiresAuth
  //   : true

  // // checking if the user is authenticated
  // if (!store.getters.isAuthenticated() && requiresAuth) {
  //   next({ name: 'login', params: { ...{ redirect: to.name }, ...to.params } })
  //   return
  // } else if (
  //   store.getters.isAuthenticated() &&
  //   !store.getters.isDataPreloaded() &&
  //   to.name !== 'preloading'
  // ) {
  //   //... comment
  //   next({
  //     name: 'preloading',
  //     params: { ...{ redirect: to.name }, ...to.params },
  //   })
  //   return
  // }

  next()
})

function setDeviceWidth(width) {
  if (width < 768) {
    return 'w-full'
  } else {
    return 'w-phone'
  }
}

configure({
  validateOnInput: true,
})

app.use(Vuesax, { theme: { colors } })
app.use(store)
app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(Shimmer)
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

// const vueGtagOptions = {
//   property: {
//     id: 'G-WBJED3VZJM',
//   },
// }
// app.use(VueGtag, vueGtagOptions)
// trackRouter(router)

app.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
      distance: 5,
      $resetCss: true,
    },
  },
})

app.provide('axios', axios) // provide axios
app.provide('dayjs', dayjs) // provide dayJS

app.provide('deviceWidth', setDeviceWidth(window.innerWidth))

app.component('Swiper', Swiper)
app.component('SwiperSlide', SwiperSlide)
app.component('InfiniteLoading', VueEternalLoading)
app.component('VOtpInput', VOtpInput)
app.component('VueLoadImage', VueLoadImage)
app.component('Sheet', Sheet)
app.component(VueCountdown.name, VueCountdown)

// app.directive('dragscroll', dragscrollNext)
// app.use(dragscrollNext)

utils(app)

app.mount('#app')
