import axios from 'axios'

let baseURL = ''
if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'staging'
) {
  baseURL = import.meta.env.VITE_API_PAYMENT_URL
}

export function routePathPaymentAPI() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return `/${import.meta.env.VITE_API_PAYMENT_VERSION}/`
  } else {
    return `/api-payment/${import.meta.env.VITE_API_PAYMENT_VERSION}/`
  }
}

const axiosPayment = axios.create({
  baseURL: baseURL,
  timeout: import.meta.env.VITE_API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

export default axiosPayment
