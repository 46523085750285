// initial state
const state = () => ({})

// getters
const getters = {
  lastURL(state) {
    return localStorage.getItem('gromura.lastURL') || null
  },
}

// actions
const actions = {
  setURL({ commit }, payload) {
    commit('SET_LAST_URL', payload)
  },
  removeURL({ commit }, payload) {
    commit('REMOVE_LAST_URL')
  },
}

// mutations
const mutations = {
  SET_LAST_URL(state, url) {
    localStorage.setItem('gromura.lastURL', url)
  },
  REMOVE_LAST_URL(state) {
    localStorage.removeItem('gromura.lastURL')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
