import * as Yup from 'yup'
import { colors } from '../themeConfig'

const phoneRegExp = /^[\+]?[(]?[1-9]{3}[)]?[0-9]{3}?[0-9]{4,7}$/
const numberRegExp = /([\d +-]+)$/
const phoneRegExpNoPlus = /^[(]?[1-9]{3}[)]?[0-9]{3}?[0-9]{4,7}$/
const digitsOnly = (value) => /^\d+$/.test(value)
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default {
  inject: ['dayjs'],
  emits: ['showLoadingIndicator', 'showToast'],
  data: () => ({
    appName: 'gromura',

    userAgent: '',

    scrollToTop: '0',

    openMenu: false,

    // deviceWidth: 'w-full',
    imageBoxUrl: 'https://via.placeholder.com/50x50',

    isUserStateComplete: false,
    maintenanceToPage: false,

    isAuthenticated: false,
    isModalConfirm: false,

    showModal: false,
    showModalMaintenance: false,
    showModalChangeLanguage: false,
    showModalComingSoon: false,
    showModalLogout: false,

    currentInstituteSlug: null,

    filter: 0,

    // VALIDATOR
    phoneSchema: Yup.object().shape({
      phone_number: Yup.string()
        .matches(phoneRegExp, 'phone_number_not_valid')
        .typeError('phone_number_required')
        .required('phone_number_required'),
      // title: Yup.string()
      //     .required('Title is required'),
      // firstName: Yup.string()
      //     .required('First Name is required'),
      // lastName: Yup.string()
      //     .required('Last name is required'),
      // dob: Yup.string()
      //     .required('Date of Birth is required')
      //     .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
      // email: Yup.string()
      //     .required('Email is required')
      //     .email('Email is invalid'),
      // password: Yup.string()
      //     .min(6, 'Password must be at least 6 characters')
      //     .required('Password is required'),
      // confirmPassword: Yup.string()
      //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
      //     .required('Confirm Password is required'),
      // acceptTerms: Yup.string()
      //     .required('Accept Ts & Cs is required')
    }),
    updateProfileSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'minName')
        .max(30, 'maxName')
        .typeError('enter_name')
        .required('enter_name')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
    }),
    updateProfileGroupSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'minName')
        .max(30, 'maxName')
        .typeError('enter_name')
        .required('enter_name')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      group: Yup.string()
        .min(2, 'minClass')
        .max(15, 'maxClass')
        .typeError('enterGroup')
        .required('enterGroup')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
    }),
    inputGroupSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'minName')
        .max(30, 'maxName')
        .typeError('enter_name')
        .required('enter_name'),
      group: Yup.string()
        .min(2, 'minClass')
        .max(15, 'maxClass')
        .typeError('enterGroup')
        .required('enterGroup'),
    }),
    callWaiterSchema: Yup.object().shape({
      itemNeed: Yup.string().required('chooseYourNeed'),
      tableRow: Yup.string()
        .typeError('insert_table_row')
        .required('insert_table_row')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      itemNeedWrite: Yup.string()
        .typeError('insertYourNeed')
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace')
        .when('itemNeed', {
          is: 'other',
          then: Yup.string().required('insertYourNeed'),
        }),
    }),
    loginSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .typeError('insertPhoneNumber')
        .required('insertPhoneNumber')
        .min(9, 'minimumPhoneNumber')
        .test('startwith', function () {
          let phoneNumber = this.parent['phoneNumber']
          if (phoneNumber) {
            return phoneNumber.startsWith('8')
              ? true
              : this.createError({
                  message: 'startWithPhoneNumber',
                  path: 'phoneNumber',
                })
          } else {
            return this.createError({
              message: 'insertPhoneNumber',
              path: 'phoneNumber',
            })
          }
        })
        .test('Digits only', 'onlyNumber', digitsOnly)
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
    }),
    registerSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .typeError('insertPhoneNumber')
        .required('insertPhoneNumber')
        .min(9, 'minimumPhoneNumber')
        .test('startwith', function () {
          let phoneNumber = this.parent['phoneNumber']
          if (phoneNumber) {
            return phoneNumber.startsWith('8')
              ? true
              : this.createError({
                  message: 'startWithPhoneNumber',
                  path: 'phoneNumber',
                })
          } else {
            return this.createError({
              message: 'insertPhoneNumber',
              path: 'phoneNumber',
            })
          }
        })
        .test('Digits only', 'onlyNumber', digitsOnly)
        .matches(/^(?!\s+$).*/, 'cannotOnlySpace'),
      name: Yup.string()
        .min(3, 'minName')
        .max(30, 'maxName')
        .typeError('enter_name')
        .required('enter_name'),
      group: Yup.string()
        .min(2, 'minClass')
        .max(15, 'maxClass')
        .typeError('enterGroup')
        .required('enterGroup'),
    }),
    phoneRegExp: phoneRegExp,
    numberRegExp: numberRegExp,
    phoneRegExpNoPlus: phoneRegExpNoPlus,
    errorStatus: [404, 500, 502, 503, 504, 505],
    loginFirst: false,
  }),
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters['auth/isUserLoggedIn']
      },
      set(userLogggedIn) {
        return this.$store.dispatch('auth/updateUserState', userLogggedIn)
      },
    },
    isInstituteOpen: {
      get() {
        return this.$store.getters['institution/isOpen']
      },
      set(isOpen) {
        return this.$store.dispatch('institution/setIsOpen', isOpen)
      },
    },
    isUnderMaintenance: {
      get() {
        // return this.underMaintenance
        return this.$store.getters['home/underMaintenanceStateGetter']
      },
      set(maintenance) {
        return this.$store.dispatch('home/setUnderMaintenance', maintenance)
      },
    },
    totalActiveOrder: {
      get() {
        return this.$store.getters['order/totalActiveOrder']
      },
      set(total) {
        return this.$store.dispatch('order/setTotalActiveOrder', total)
      },
    },
    menuMode: {
      get() {
        return this.$store.getters['global/menuMode']
      },
      set(mode) {
        return this.$store.dispatch('global/setMenuMode', mode)
      },
    },
    hideDineIn: {
      get() {
        return this.$store.getters['global/hideDineIn']
      },
      set(mode) {
        return this.$store.dispatch('global/setHideDineIn', mode)
      },
    },
    // headerMode: {
    //   get() {
    //     return this.$store.getters['global/headerMode']
    //   },
    //   set(val) {
    //     return this.$store.commit('global/setHeaderMode', val)
    //   },
    // },
    // headerData: {
    //   get() {
    //     return this.$store.getters['global/headerData']
    //   },
    //   set(val) {
    //     return this.$store.commit('global/setHeaderData', val)
    //   },
    // },
    // totalFilter: {
    //   get() {
    //     return this.filter
    //   },
    //   set(val) {
    //     return (this.filter = val)
    //   },
    // },
  },
  mounted() {
    this.userAgent = navigator.userAgent

    this.currentInstituteSlug = this.$route.params.slug

    // this.setDeviceWidth(window.innerWidth)
  },
  methods: {
    openRoute(routeName) {
      this.$router.push({
        name: routeName,
        query: { lang: this.$route.query.lang },
      })
    },
    toLoginPage() {
      if (this.isLoggedIn !== true) {
        // Temporary set to login page
        this.$router.push({
          name: 'login',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      }
    },
    checkIsAuthenticated() {
      const accessToken = localStorage.getItem('gromura.accessToken') || null

      if (accessToken !== null) {
        this.$router.push({
          name: 'home',
          params: { slug: this.$route.params.slug },
          query: { lang: this.$route.query.lang },
        })
      }
    },
    checkMaintenance() {
      if (this.isUnderMaintenance) {
        if (this.maintenanceToPage) this.$router.push({ name: 'maintenance' })
        else this.$emit('showModalMaintenance', this.isUnderMaintenance)
      }
    },
    // setDeviceWidth(width) {
    //   if (width < 768) {
    //     this.deviceWidth = 'w-full'
    //   } else {
    //     this.deviceWidth = 'w-phone'
    //   }
    // },
    isPhoneNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    mergeText(text1, text2) {
      return text1 + ' ' + text2
    },
    isArrayEqual(array1, array2) {
      if (array1.length !== array2.length) return false
      return array1.every((value, index) => value === array2[index])
    },

    // replaceFieldWithName(error, field) {
    //   if (error !== undefined) {
    //     if (error.includes('{field}')) {
    //       return error.replace('{field}', field)
    //     } else {
    //       return error
    //     }
    //   }
    // },
    persentage(price, discount) {
      return Math.floor(((price - discount) / price) * 100)
    },
    imageUrlAlternate(event) {
      crossOriginIsolated.log('called imageUrlAlternate')
      event.target.src = '@/assets/image/placeholder.png'
    },
    imageUrlAlternateBox(event) {
      event.target.src = '@/assets/image/placeholder.png'
    },
    formatDateDay(
      date,
      options = {
        month: 'long',
        day: 'numeric',
      }
    ) {
      return new Date(date).toLocaleDateString('id-ID', options)
    },
    formattedDate(value, dateFormat = 'DD MMMM YYYY') {
      if (!value) return ''
      const formattedDate = this.dayjs(value).format(dateFormat)
      return formattedDate
    },
    dayJSDiff(value) {
      const start = this.dayjs()
      const end = this.dayjs(value)
      // console.log(start, '-', end)
      const different = end.diff(start)
      return { isPositive: different > 0, different }
      // format('YYYY-MM-DD HH:mm:ss')
    },
    hoursMinutesFromTwoDates(date1, date2) {
      const localStartTime = this.dayjs(date1)
      const localEndTime = this.dayjs(date2)

      const hoursDiff = localEndTime.diff(localStartTime, 'h')
      const minutesDiff = localEndTime.diff(localStartTime, 'm')

      let hoursMinutes = ''
      if (hoursDiff > 0) {
        hoursMinutes =
          hoursDiff +
          ' ' +
          this.$t('time_hour') +
          ' ' +
          minutesDiff +
          ' ' +
          this.$t('time_minute')
      } else {
        hoursMinutes = minutesDiff + ' ' + this.$t('time_minute')
      }
      return hoursMinutes
    },
    dateStillActive(endDate, startToday = true, startDate = '') {
      if (startToday) startDate = new Date()
      endDate = new Date(endDate)
      return endDate > startDate
    },
    dateDiffDays(endDate, startToday = true, startDate = '') {
      if (startToday) startDate = new Date()
      endDate = new Date(endDate)
      const diffTime = endDate - startDate
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    },
    diffPrice(price, discountedPrice) {
      return discountedPrice - price
    },
    diffMinute(isAbsolute = true) {
      const diff = new Date().getTimezoneOffset()
      if (isAbsolute) {
        return Math.abs(diff)
      }
      return diff
    },
    hoursMinutesFromDate(date, add = null) {
      if (add === null) {
        return this.dayjs(date).format('HH:mm')
      } else {
        return this.dayjs(date).add(add.num, add.type).format('HH:mm')
      }
    },
    getDateRange(totalDay = 30) {
      const curr = new Date()
      const dateList = []
      // dateList.push(
      //   new Date(curr.setDate(curr.getDate())).toISOString().slice(0, 10)
      // )

      for (let i = 1; i <= totalDay; i++) {
        const first = curr.getDate() + 1
        const day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
        dateList.push(day)
      }
      return dateList
    },
    getRangeInteger(start, end, length = end - start) {
      return Array.from({ length }, (_, i) => start + i)
    },
    timeEstimatedFromDate(date, addMinutes = 5) {
      const startTime = this.dayjs(date).format('HH:mm')

      const endTime = this.dayjs(date).add(addMinutes, 'm').format('HH:mm')
      return `${startTime} - ${endTime}`
    },
    switchIndxOrderStatus(status) {
      switch (status) {
        case 'pending':
          return 'order-confirmed'
        case 'confirm':
          return 'on-progress'
        case 'almost-done':
          return 'almost-done'
        case 'ready':
          return 'order-ready'
        case 'done':
          return 'done'
        case 'cancel':
          return 'cancel'
        default:
          return status
      }
    },
    mergeTwoArrayByKey(arr1, arr2, key = 'id') {
      return arr1
        .filter((elem) => !arr2.find((subElem) => subElem[key] === elem[key]))
        .concat(arr2)
    },
    toDatetime(date) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      const hours = ('0' + date.getHours()).slice(-2)
      const minutes = ('0' + date.getMinutes()).slice(-2)
      const seconds = ('0' + date.getSeconds()).slice(-2)

      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
      )
    },
    async fetchGuest(slug) {
      if (slug !== undefined || this.$route.params.slug !== undefined) {
        const instituteId = slug !== undefined ? slug : this.$route.params.slug
        await this.$store
          .dispatch('guest/fetchGuest', {
            instituteId: instituteId,
          })
          .then((response) => {
            const responseData = response.data
            this.changeMaintenanceStatus(!responseData.is_maintenance)
          })
          .catch((err) => {
            if (
              typeof err.response !== 'undefined' &&
              err.response.hasOwnProperty('status')
            ) {
              if (this.errorStatus.includes(err.response.status)) {
                return this.$router.push({
                  name: 'not-found',
                })
              }
            }
          })
      }
    },
    async updateInstitute(slug) {
      if (slug !== undefined || this.$route.params.slug !== undefined) {
        const instituteId = slug !== undefined ? slug : this.$route.params.slug
        await this.$store
          .dispatch('institution/updateInstitute', {
            instituteId: instituteId,
          })
          .then((response) => {
            const responseData = response.data
            if (responseData.currentInstituteSlug !== instituteId) {
              this.currentInstituteSlug = responseData.currentInstituteSlug
              return this.$router.push({
                name: 'not-found',
              })
            }

            const setting = responseData.setting
            if (setting !== null) {
              if ('setting_json' in setting) {
                this.updateThemeConfig(setting.setting_json)
              }
            } else {
              this.resetThemeConfig()
            }
            if (slug !== undefined) {
              this.toInitialPage(instituteId)
            }
          })
          .catch((err) => {
            if (
              typeof err.response !== 'undefined' &&
              err.response.hasOwnProperty('status')
            ) {
              if (err.response.status === 401) {
                this.currentInstitute(slug)
                if (localStorage.getItem('gromura.accessToken') !== null) {
                  this.isLoggedIn = false
                  localStorage.removeItem('gromura.accessToken')
                }
              }
              if (this.errorStatus.includes(err.response.status)) {
                return this.$router.push({
                  name: 'not-found',
                })
              }
            }
          })
      }
    },
    async currentInstitute(slug = undefined) {
      if (slug !== undefined || this.$route.params.slug !== undefined) {
        const instituteId = slug !== undefined ? slug : this.$route.params.slug
        await this.$store
          .dispatch('institution/institutionDetail', { id: instituteId })
          .then((response) => {
            const responseData = response.data
            const setting = responseData.attributes.setting
            if (setting !== null) {
              if ('setting_json' in setting) {
                this.updateThemeConfig(setting.setting_json)
              }
            } else {
              this.resetThemeConfig()
            }
            if (slug !== undefined) {
              this.toInitialPage(instituteId)
            }
          })
          .catch((err) => {
            if (
              typeof err.response !== 'undefined' &&
              err.response.hasOwnProperty('status')
            ) {
              if (this.errorStatus.includes(err.response.status)) {
                return this.$router.push({
                  name: 'not-found',
                  query: {
                    lang: this.$route.query.lang,
                  },
                })
              }
            }
          })
      }
    },
    updateThemeConfig(setting) {
      if ('colors' in setting) {
        const colors = setting.colors
        this.$vs.theme({
          primary: colors.primary,
          secondary: colors.secondary,
          success: colors.success,
          warning: colors.warning,
          danger: colors.danger,
          dark: colors.dark,
        })
      }
      if ('show_all_menu' in setting) {
        this.changeShowAllMenu(setting.show_all_menu)
      }
      if ('hide_dine_in' in setting) {
        this.changeHideDineIn(setting.hide_dine_in)
      }
    },
    resetThemeConfig() {
      this.$vs.theme({
        ...colors,
      })
      this.menuMode = 'tenant'
      this.hideDineIn = false
    },
    arrayToString(array, key) {
      return array.map((item) => item[key]).join(', ')
    },
    onCopy(e) {
      const messageAlert = {
        show: true,
        message: e.text + ' ' + this.$t('copy_to_clipboard_success'),
        type: 'default',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    onError(e) {
      const messageAlert = {
        show: true,
        message: e.text + ' ' + this.$t('copy_to_clipboard_failed'),
        type: 'error',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    changeMaintenanceStatus(
      is_open,
      toPage = false,
      title = this.$t('sorry'),
      desc = this.$t('service_closed'),
      needRedirect = true
    ) {
      this.isInstituteOpen = is_open === 1 || is_open === true
      // if (is_open) this.toLoginPage()
      if (this.maintenanceToPage || toPage) {
        this.isUnderMaintenance = is_open
        this.$router.push({
          name: 'maintenance',
          params: { slug: this.$route.params.slug },
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        this.$emit('showGlobalModalMaintenance', {
          show: !is_open,
          title: title,
          desc: desc,
        })
        if (!is_open && needRedirect) {
          // this.$router.push({
          //   name: 'home',
          //   params: { slug: this.$route.params.slug },
          //   query: {
          //     lang: this.$route.query.lang,
          //   },
          // })
          this.toInitialPage()
        }
      }
    },
    async checkOrderActive() {
      await this.$store
        .dispatch('order/activeCount', {
          orderStatus: 'all',
        })
        .then((response) => {
          this.totalActiveOrder = response.data.counted
        })
        .catch((err) => {
          if (
            typeof err.response !== 'undefined' &&
            err.response.hasOwnProperty('status')
          ) {
            if (err.response.status === 401) {
              if (localStorage.getItem('gromura.accessToken') !== null) {
                this.isLoggedIn = false
                localStorage.removeItem('gromura.accessToken')
              }
            } else {
              console.log(e)
            }
          }
        })
    },
    async checkInstitute() {
      if (this.$route.params.slug !== undefined) {
        let instituteId = null
        if (this.isLoggedIn !== true) {
          instituteId = this.$route.params.slug
        }
        await this.$store
          .dispatch('home/dashboard', { instituteId })
          .then((response) => {
            let dashboardData = response.data
            if ('is_open' in dashboardData) {
              this.changeMaintenanceStatus(dashboardData.is_open)
            }
            if (dashboardData.data !== undefined) {
              dashboardData = dashboardData.data
              if (dashboardData.currentInstitute !== undefined) {
                if ('is_open' in dashboardData.currentInstitute.data) {
                  this.changeMaintenanceStatus(
                    dashboardData.currentInstitute.data.is_open
                  )
                }
              }
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
    async checkTenantIsOpen() {
      if (this.$route.params.slug !== undefined) {
        const instituteId = this.$route.params.slug
        await this.$store
          .dispatch('tenant/tenantAutoOpen', { instituteId })
          .then((response) => {
            return response
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },
    changeShowAllMenu(showAllMenu) {
      if (showAllMenu === 1) {
        this.menuMode = 'allMenu'
      } else {
        this.menuMode = 'tenant'
      }
    },
    changeHideDineIn(hideDineIn) {
      this.hideDineIn = hideDineIn === 1
    },
    checkPageConfig() {
      this.$store
        .dispatch('global/pageConfig')
        .then((response) => {
          if ('show_all_menu' in response.data) {
            this.changeShowAllMenu(response.data.show_all_menu)
          }
          if ('hide_dine_in' in response.data) {
            this.changeHideDineIn(response.data.hide_dine_in)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async checkAPIHealth(instituteId = undefined) {
      await this.$store
        .dispatch('global/checkHealth')
        .then((response) => {
          if (
            typeof response !== 'undefined' &&
            response.hasOwnProperty('status')
          ) {
            const success = response.status === 200
            if (!success) {
              this.changeMaintenanceStatus(!success, true)
            } else {
              this.isUnderMaintenance = !success
              this.checkInstitute()
              // this.checkTenantIsOpen()
              if (this.isLoggedIn === true) {
                this.updateInstitute(instituteId)
                this.checkOrderActive()
                this.checkPageConfig()
              } else {
                this.fetchGuest(instituteId)
                this.currentInstitute(instituteId)
              }
            }
          }
        })
        .catch((e) => {
          console.log(e)
          this.changeMaintenanceStatus(true, true)
        })
    },
    toInitialPage(insituteId = undefined) {
      // if (this.menuMode === 'allMenu') {
      //   return this.$router.push({
      //     name: 'menu',
      //     params: {
      //       slug: this.$route.params.slug,
      //       tenantId: 'all',
      //     },
      //     query: {
      //       lang: this.$route.query.lang,
      //     },
      //   })
      // } else {
      if (this.loginFirst && !this.isLoggedIn) {
        return this.$router.push({
          name: 'login',
          params: {
            slug:
              insituteId !== undefined ? insituteId : this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        return this.$router.push({
          name: 'tenant',
          params: {
            slug:
              insituteId !== undefined ? insituteId : this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      }
      // }
    },
    toInputGroupPage() {
      return this.$router.push({
        name: 'login-input-group',
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    },
    checkLength(length, obj) {
      if (typeof obj === 'string' || obj instanceof String) {
        return length > obj.split(' ').length
      }
      return length > obj
    },
    getFirstNWord(str, last, start = 0, suffix = '...') {
      const arr = str.split(' ')
      if (this.checkLength(last, arr.length)) {
        return arr.slice(start, arr.length).join(' ')
      }
      return arr.slice(start, last).join(' ') + suffix
    },
    getFirstNLetter(str, last, start = 0, suffix = '...') {
      const arr = str.split(' ')
      if (arr.length > 0) {
        if (arr[0].length >= 15) {
          return str.slice(start, 15) + suffix
        }
      }

      if (this.checkLength(last, str.length)) {
        return str.slice(start, str.length)
      }
      return str.slice(start, last) + suffix
    },
    generateGreeting() {
      const today = new Date()
      const curHr = today.getHours()
      let currentGreeting = 'dinner'

      if (curHr < 10) {
        currentGreeting = 'breakfast'
      } else if (curHr < 16) {
        currentGreeting = 'lunch'
      } else {
        currentGreeting = 'dinner'
      }
      return `It's ${currentGreeting} time! 😋`
    },
    getSlug() {
      return this.$route.params.slug
    },
    getStatusColor(status) {
      switch (status) {
        case 'pending':
          return 'bg-yellow-50 text-yellow-400'
        case 'processed':
          return 'bg-orange-50 text-orange-400'
        case 'completed':
          return 'bg-green-50 text-green-400'
        case 'failed' || 'canceled':
          return 'bg-red-50 text-red-400'
        default:
          return 'bg-white text-black'
      }
    },
    checkEmptyObject(obj) {
      // because Object.keys(new Date()).length === 0;
      // we have to do some additional check
      return (
        obj && // 👈 null and undefined check
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      )
    },
    instituteShowGroup() {
      const institutes = ['shi-groove']
      return institutes.includes(this.$route.params.slug)
    },
  },
}
