<template>
  <header class="fixed top-0 z-10 bg-white shadow" :class="deviceWidth">
    <nav v-if="mode === 'full'" class="flex flex-col items-center p-4">
      <div class="flex w-full justify-between">
        <!-- <p v-if="!hideTitle" class="text-ink-dark text-xl font-bold">
          {{ $t('hello') }}, {{ fullName }}
        </p>
        <p v-else class="text-ink-dark text-2xl font-bold">
          {{ $t('welcome') }}
        </p> -->
        <div class="mt-1 flex flex-col">
          <div>
            <img class="h-6 w-auto" src="@/assets/image/logo.png" alt="Gromura" />
          </div>

          <p class="mt-4 flex cursor-pointer text-xs font-bold text-gray-800">
            Gudang - Pasar Kemis <Repeat2 class="ml-2 h-4 w-4 text-black" />
          </p>
        </div>

        <div class="flex flex-row pt-1">
          <CartButton
            v-if="showTrolly"
            :total-cart="cartCount"
            :custom-class="`ml-2`"
          />
          <a
            v-if="showNotif"
            class="ml-2 cursor-pointer p-1 text-sm text-gray-600 hover:text-gray-600"
            @click="
              $router.push({
                name: 'notification',
                query: { lang: $route.query.lang },
              })
            "
          >
            <BellIcon class="h-5 w-5" />
          </a>
        </div>
      </div>

      <div class="mt-2 flex w-full">
        <div class="relative w-full text-gray-400 focus-within:text-gray-500">
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <button
              type="submit"
              class="focus:shadow-outline p-1 focus:outline-none"
            >
              <SearchIcon class="h-4 w-4" />
            </button>
          </span>
          <input
            type="text"
            name="searchProduct"
            class="w-full rounded-lg border-gray-300 py-2 pl-10 text-sm focus:border-gray-400 focus:border-teal-400 focus:text-gray-500 focus:ring focus:ring-gray-100 focus:ring-opacity-50"
            placeholder="Cari produk"
            autocomplete="off"
          />
        </div>
      </div>
    </nav>

    <!-- <nav v-if="mode === 'narrow'" class="flex items-center justify-between p-4">
      <div
        class="relative w-full text-gray-400 focus-within:text-gray-500"
        @click="
          $router.push({
            name: 'product-search',
            query: { lang: $route.query.lang },
          })
        "
      >
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <button
            type="submit"
            class="focus:shadow-outline p-1 focus:outline-none"
          >
            <SearchIcon class="h-5 w-5" />
          </button>
        </span>
        <input
          type="text"
          name="searchProduct"
          class="focus:border-ink-primary w-full border-gray-300 py-2 pl-10 text-base focus:border-gray-400 focus:text-gray-500 focus:ring focus:ring-gray-100 focus:ring-opacity-50"
          :placeholder="$t('find_food_or_drink')"
          autocomplete="off"
        />
      </div>
      <div class="ml-3 flex flex-row justify-end">
        <CartButton
          v-if="showTrolly"
          :total-cart="cartCount"
          :custom-class="`ml-2`"
        />
        <a
          v-if="showNotif"
          class="hover:text-ink-dark ml-1 cursor-pointer p-1 text-sm text-gray-600"
          @click="
            $router.push({
              name: 'notification',
              query: { lang: $route.query.lang },
            })
          "
        >
          <BellIcon class="h-5 w-5" />
        </a>
      </div>
    </nav> -->

    <!-- <div class="grid grid-cols-2 divide-x-2 px-4 pb-3">
      <div class="flex w-full cursor-pointer">
        <div
          class="flex h-8 w-8 flex-none justify-center self-center rounded-full border-2"
        >
          <div class="self-center">
            <WalletIcon class="text-ink-primary h-4 w-4" />
          </div>
        </div>
        <div class="ml-2 self-center truncate">
          <div class="flex">
            <p class="text-sm text-gray-400">Rp</p>
            <p class="text-md text-ink-dark pl-1 font-semibold line-clamp-1">
              <Currency :total-in-string="balance" :with-prefix="false" />
            </p>
          </div>
        </div>
        <div v-if="showBalance" class="mr-2 flex-none self-center pl-1">
          <ArrowRightIcon class="text-ink-primary h-4 w-4" />
        </div>
      </div>

      <div class="flex w-full cursor-pointer pl-2">
        <div
          class="flex h-8 w-8 flex-none justify-center self-center rounded-full border-2"
        >
          <div class="self-center">
            <PointsIcon class="text-ink-primary h-4 w-4" />
          </div>
        </div>
        <div class="ml-2 flex self-center truncate">
          <p class="text-md text-ink-dark font-semibold line-clamp-1">
            <Currency
              :total-in-string="points"
              :with-prefix="false"
              leading-text="Points"
            />
          </p>
        </div>
        <div v-if="showPoints" class="flex-none self-center pl-1">
          <ArrowRightIcon class="text-ink-primary h-4 w-4" />
        </div>
      </div>
    </div> -->
  </header>
</template>

<script>
import SearchIcon from '@/assets/svg/search.svg'
import BellIcon from '@/assets/svg/bell.svg'
import WalletIcon from '@/assets/svg/wallet.svg'
import PointsIcon from '@/assets/svg/points.svg'
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'
import CartButton from '@/components/button/CartButton.vue'
import Currency from '@/components/currency/Currency.vue'
import { Repeat2 } from 'lucide-vue-next'
export default {
  name: 'HeaderNavBar',
  components: {
    SearchIcon,
    BellIcon,
    WalletIcon,
    PointsIcon,
    ArrowRightIcon,
    CartButton,
    Currency,
    Repeat2
  },
  inject: ['deviceWidth'],
  props: {
    title: {
      type: String,
      required: false,
      default: 'My Title',
    },
    showQR: {
      type: Boolean,
      default: true,
    },
    showNotif: {
      type: Boolean,
      default: false,
    },
    showTrolly: {
      type: Boolean,
      default: false,
    },
    cartCount: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: 'full',
      validator: (value) => ['full', 'narrow'].includes(value),
    },
    fullName: {
      type: String,
      default: '',
      required: true,
    },
    instituteName: {
      type: String,
      default: '',
      required: false,
    },
    showBalance: {
      type: Boolean,
      default: true,
    },
    balance: {
      type: String,
      default: '0',
      required: false,
    },
    showPoints: {
      type: Boolean,
      default: true,
    },
    points: {
      type: String,
      default: '0',
      required: false,
    },
    instituteId: {
      type: String,
      default: '',
      required: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // firstName: 'Idin',
    // tenantName: 'SMK Adi Sanggoro',
  }),
  mounted() {},
  methods: {},
}
</script>
