// @ts-nocheck
import { defineStore, acceptHMRUpdate } from 'pinia'
import axios from '@/plugins/axios.js'

/**
 * Simulate a login
 * @param {string} a
 * @param {string} p
 */
function apiLogin(a, p) {
  if (a === 'ed' && p === 'ed') return Promise.resolve({ isAdmin: true })
  if (p === 'ed') return Promise.resolve({ isAdmin: false })
  return Promise.reject(new Error('invalid credentials'))
}

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    name: 'Idin',
    isAdmin: true,
  }),

  actions: {
    logout() {
      this.$patch({
        name: '',
        isAdmin: false,
      })

      // we could do other stuff like redirecting the user
    },

    /**
     * Attempt to login a user
     * @param {string} user
     * @param {string} password
     */
    login(instituteId, { phone_number }) {
      // const userData = await apiLogin(user, password)
      const payload = {
        institute_id: instituteId,
        phone_number: phone_number,
        role: 'user', // user ?? tenant
        grant_type: import.meta.env.GRANT_TYPE,
        client_id: import.meta.env.CLIENT_ID,
        client_secret: import.meta.env.CLIENT_SECRET,
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/user/login`, payload)
          .then((response) => {
            const responseData = response.data.data
            if (response.status === 200) {
              resolve({
                status: response.status,
                message: 'otp_sent',
                data: response.data,
              })
            } else {
              reject({
                status: response.status,
                message: response.message,
                data: response.data,
              })
            }
            // axios.defaults.headers.common['Authorization'] = `Bearer ${userData.isAdmin ? 'admin' : 'user'}`
            // this.$patch({
            //   name: user,
            //   ...userData,
            // })
          })
          .catch((error) => {
            console.log('error ', error)
            reject(error)
          })
      })
    },
  },
})
