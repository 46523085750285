import Index from '@/views/Index.vue'
import Splash from '@/views/Splash.vue'
import Example from '@/views/example/Index.vue'
import Product from '@/views/Product.vue'

import NotFound from '@/views/NotFound.vue'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import Warehouse from '@/views/Warehouse.vue'
import Investor from '@/views/Investor.vue'
import Platform from '@/views/Platform.vue'
import Partnership from '@/views/Partnership.vue'
import Insights from '@/views/Insights.vue'
import ContactUs from '@/views/ContactUs.vue'
import SolutionForMarketing from '@/views/services/SolutionForMarketing.vue'
import SolutionForSales from '@/views/services/SolutionForSales.vue'
import SolutionForSupplyChain from '@/views/services/SolutionForSupplyChain.vue'
import AboutUsOurTeam from '@/views/about-us/OurTeam.vue'
import AboutUsOurVisionMission from '@/views/about-us/OurVisionMission.vue'

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [

  // {
  //   path: '/splash',
  //   name: 'splash',
  //   meta: { title: 'Splash', layout: 'mobile' },
  //   component: Splash,
  //   // example of route level code-splitting
  //   // this generates a separate chunk (About.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import('./views/About.vue')
  // },
  {
    path: '/',
    name: 'home',
    meta: { title: 'Home', layout: 'default' },
    component: Home,
  },
  {
    path: '/product',
    name: 'product',
    meta: { title: 'Product', layout: 'mobile' },
    component: Product,
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: 'Login', layout: 'blank' },
    component: Login,
  },
  {
    path: '/investor',
    name: 'investor',
    meta: { title: 'Investor', layout: 'default_two' },
    component: Investor,
  },
  {
    path: '/platform',
    name: 'platform',
    meta: { title: 'Platform', layout: 'default' },
    component: Platform,
  },
  {
    path: '/partnership',
    name: 'partnership',
    meta: { title: 'Partnership', layout: 'default' },
    component: Partnership,
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    meta: { title: 'Warehouse', layout: 'default' },
    component: Warehouse,
  },
  {
    path: '/insights',
    name: 'insights',
    meta: { title: 'Insights', layout: 'default' },
    component: Insights,
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    meta: { title: 'ContactUs', layout: 'default' },
    component: ContactUs,
  },
  {
    path: '/service-solution-for-marketing',
    name: 'service-solution-for-marketing',
    meta: { title: 'Solution For Marketing', layout: 'default' },
    component: SolutionForMarketing,
  },
  {
    path: '/service-solution-for-sales',
    name: 'service-solution-for-sales',
    meta: { title: 'Solution For Sales', layout: 'default' },
    component: SolutionForSales,
  },
  {
    path: '/service-solution-for-supply-chain',
    name: 'service-solution-for-supply-chain',
    meta: { title: 'Solution For Supply Chain', layout: 'default' },
    component: SolutionForSupplyChain,
  },
  {
    path: '/about-us-our-team',
    name: 'about-us-our-team',
    meta: { title: 'About Us Our Team', layout: 'default' },
    component: AboutUsOurTeam,
  },
  {
    path: '/about-us-our-vision-mission',
    name: 'about-us-our-vision-mission',
    meta: { title: 'About Us Our Vision Mission', layout: 'default' },
    component: AboutUsOurVisionMission,
  },
  {
    path: '/example',
    name: 'example',
    meta: { title: 'Example', layout: 'default' },
    component: Example,
    // example of route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('./views/About.vue')
  },
  { path: '/404', name: 'not-found', component: NotFound },
  { path: '/:path(.*)', component: NotFound },
]
