<template>
  <HeaderNavBarHome
    :key="$route.params.slug"
    :title="$t('home')"
    :mode="headerMode"
    :user-full-name="headerData.fullName"
    :institute-name="headerData.instituteName"
    :balance="headerData.balance"
    :points="headerData.points"
    :institute-id="headerData.instituteId"
    :hide-title="!headerData.isAuthenticated"
    :cart-count="headerData.totalCart"
    :show-trolly="true"
    :show-notif="false"
    :show-balance="false"
    :show-points="false"
  />
  <section id="content" class="pt-40 container mx-auto flex flex-col bg-white px-4">
    <div class="intro-y flex w-full flex-col">
      <div
        v-if="productData.length === 0"
        class="mt-5 grid grid-cols-12 gap-5 border-b pb-8"
      >
        <div
          class="relative col-span-12 flex h-24 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center sm:col-span-4 2xl:col-span-3"
        >
          <span class="mb-2 block text-xs font-medium text-gray-400">
            Tidak ada produk yang dapat ditampilkan
          </span>
        </div>
      </div>
      <div v-else class="flex w-full flex-col pb-8">
        <div class="grid grid-cols-2 gap-4">
          <div
            v-for="item in productData"
            :key="item.product.id"
            class="box flex h-full w-full flex-col shadow-md"
          >
            <div class="flex w-full">
              <ImageLoader
                :image="
                  item.product.images !== undefined &&
                  item.product.images.length > 0
                    ? item.product.images[0].image
                    : null
                "
                :alt="item.product.name"
                rounded-class="rounded-tr-md rounded-tl-md w-full cursor-pointer"
                @click="productPreview(item)"
              />
            </div>
            <div class="flex h-full w-full flex-col justify-between">
              <div class="flex w-full flex-col px-2 md:px-4 pb-4 pt-2">
                <p class="text-ellipsis line-clamp-1 text-sm font-medium text-gray-700">
                  {{ item.product.name }}
                </p>
                <div class="flex w-full text-sm font-medium text-red-500">
                  <Currency :total-in-string="item.product.price.toString()" />
                </div>
                <div
                  class="mt-2 flex items-center text-sm border-t pt-2 text-green-600"
                >
                  <Layers class="mr-2 h-4 w-4" /> Stok:
                  {{ item.stock }}
                </div>
              </div>
              <div
                v-if="item.stock !== 0"
                class="flex cursor-pointer items-center justify-center rounded-bl-md rounded-br-md bg-teal-500 px-2 py-3 text-center text-white hover:bg-teal-600"
                @click="addItem(item)"
              >
                <PlusCircle class="mr-1 h-4 w-4 text-white" />
                <span class="flex text-xs md:text-sm"> Tambahkan </span>
              </div>
              <div
                v-else
                class="flex items-center justify-center rounded-bl-md rounded-br-md bg-gray-500 px-2 py-3 text-center text-white md:py-2 lg:py-3"
              >
                <AlertCircle class="mr-1 h-4 w-4 text-white" />
                <span class="flex text-sm"> Stok Habis </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="failed-notification" class="toastify-content flex hidden">
      <!-- <XCircleIcon class="text-danger" /> -->
      <div class="ml-4 mr-4">
        <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
        <div class="font-medium">{{ status }}</div>
        <div class="mt-1 text-slate-500">{{ message }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'
import Button from '@/components/button/Button.vue'
import HeaderNavBarHome from '@/components/navbar/HeaderNavBarHome.vue'

import Toastify from 'toastify-js'
import { Search, PlusCircle, AlertCircle, Layers, Repeat2 } from 'lucide-vue-next'
import ImageLoader from '@/components/loader/ImageLoader.vue'
import Currency from '@/components/currency/Currency.vue'
import ShimmerCard from '@/components/loader/ShimmerCard.vue'

export default {
  name: 'HomePage',
  components: {
    HeaderNavBarHome,
    ImageLoader,
    Currency,
    ShimmerCard,
    Search,
    PlusCircle,
    AlertCircle,
    Layers,
    Button,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const storeId = ref('c7bfe8bb-27ce-423c-8fb1-af51136e86a7')

    let transaction = reactive({})
    const activeProductStock = reactive({})

    const tax = ref(11)
    const subTotal = ref(0)
    const total = ref(0)

    const isFormLoading = ref(false)
    const isLoading = ref(false)

    const pingSignal = ref(false)
    const timer = ref(undefined)

    const productPreviewModal = ref(false)
    const detailTrxModal = ref(false)
    const cartPreview = ref(false)

    const status = ref('')
    const message = ref('')

    let productData = ref([])
    let productDataSelected = ref([])

    let metaData = reactive({
      page: 1,
      take: 6,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 12,
      search: '',
      order: 'ASC',
      // type: null,
    })

    onMounted(async () => {
      // const storeId = store.getters['store/storeId']

      if (storeId.value === null) {
        setTimeout(() => {
          getProduct()
        }, 2000)
      } else {
        getProduct()
      }
    })

    const getProduct = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        productData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      await store
        .dispatch('productStock/getByStore', {
          store_id: storeId.value,
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            productData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getProduct(true)
      }, 500)
    }

    const productPreview = (data) => {
      Object.assign(activeProductStock, data)
      productPreviewModal.value = true
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const resetForm = () => {
      subTotal.value = 0
      total.value = 0

      productData.value = []
      productDataSelected.value = []
    }

    const addItem = (product) => {
      pingSignal.value = true
      if (productDataSelected.value.length === 0) {
        product.quantity = null
        product.price = null
        // product.quantity_bonus = null
        product.price_discount = null
        product.total_price = 0
        productDataSelected.value = [product]
      } else {
        let isDuplicate = false
        productDataSelected.value.forEach((item) => {
          if (item.id === product.id) {
            isDuplicate = true
          }
        })
        if (isDuplicate === false) {
          product.quantity = null
          product.price = null
          // product.quantity_bonus = null
          product.price_discount = null
          product.total_price = 0
          productDataSelected.value = [...productDataSelected.value, product]
        }
      }

      setTimeout(() => {
        pingSignal.value = false
      }, 1500)
    }

    const removeItem = (product) => {
      productDataSelected.value = productDataSelected.value.filter(
        (item) => item.id != product.id
      )
    }

    const changeQuantity = (product, bonus, added) => {
      // if (bonus === true) {
      //   if (added === true) {
      //     if (product.quantity_bonus === null) {
      //       product.quantity_bonus = 1
      //     } else {
      //       product.quantity_bonus = product.quantity_bonus + 1
      //     }
      //   } else {
      //     if (product.quantity_bonus !== 0) {
      //       product.quantity_bonus = product.quantity_bonus - 1
      //     }
      //   }
      // } else {
      if (added === true) {
        if (product.quantity === null) {
          product.quantity = 1
        } else {
          product.quantity = product.quantity + 1
        }
      } else {
        if (product.quantity !== 0) {
          product.quantity = product.quantity - 1
        }
      }
      // }

      calculateTotalPriceItem(product)
    }

    const calculateTotalPriceItem = (productStock) => {
      productStock.total_price =
        productStock.quantity * productStock.product.price -
        productStock.price_discount

      calculateTotalPrice()
    }

    const calculateTotalPrice = () => {
      let totalPrice = 0
      productDataSelected.value.forEach((item) => {
        totalPrice += item.total_price
      })

      if (tax.value.toString() !== '0') {
        subTotal.value = totalPrice - Math.round((totalPrice / 100) * tax.value)
      } else {
        subTotal.value = totalPrice
      }

      total.value = totalPrice
    }

    const createTransaction = async () => {
      if (productDataSelected.value.length === 0) {
        status.value = 'Gagal'
        message.value = 'Mohon untuk menambahkan item terlebih dahulu'
        setTimeout(() => {
          showToast()
        }, 200)
        return
      }

      let dataNotValid = false
      productDataSelected.value.forEach((item) => {
        if (item.quantity === 0 || item.quantity === null) {
          dataNotValid = true
        }
        //  else if (item.price * item.quantity <= item.price_discount) {
        //   status.value = 'Gagal'
        //   message.value = 'Harga Diskon tidak boleh melebihi harga beli'
        //   setTimeout(() => {
        //     showToast()
        //   }, 200)
        //   dataNotValid = true
        // }
      })

      if (dataNotValid === true) {
        status.value = 'Gagal'
        message.value = 'Quantity tidak boleh kosong'
        setTimeout(() => {
          showToast()
        }, 200)
        return
      }

      if (productDataSelected.value.length === 0) {
        return
      }

      isFormLoading.value = true

      const products = []

      productDataSelected.value.forEach((item) => {
        const product = {
          product_stock_id: item.id,
          quantity: item.quantity,
          // price: item.price,
          // quantity_bonus: item.quantity_bonus,
          // price_discount: item.price_discount,
        }

        products.push(product)
      })

      // const storeId = store.getters['store/storeId']

      let payload = {
        store_id: storeId.value,
        // customer_id: customer.value,
        // user_id: activeUser.id,
        // payment_method_id: paymentMethod.value,
        channel: 'agent-web',
        tax_id: Number(tax.value),
        products,
      }

      await store
        .dispatch('transaction/create', payload)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            setTimeout(() => {
              cartPreview.value = false

              resetForm()
              getProduct(true)

              Object.assign(transaction, response.data)
              detailTrxModal.value = true
            }, 500)

            // getProduct()
            // setTimeout(() => {
            //   router.push({
            //     name: 'transaction-detail',
            //     params: {
            //       id: response.data.id,
            //       slug: route.params.slug,
            //     },
            //     query: {
            //       lang: route.query.lang,
            //       print: 'true',
            //     },
            //   })
            // }, 500)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
        })
        .catch((error) => {
          console.log('error ', error)
          console.log('error.response ', error.response)
          const responseData = error.response.data
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const onError = (msg) => {
      status.value = 'Gagal'
      message.value = msg
      // show toast
      setTimeout(() => {
        showToast()
      }, 200)
    }

    return {
      t,
      n,
      store,
      route,
      router,

      transaction,
      activeProductStock,

      productPreviewModal,
      productPreview,
      detailTrxModal,
      cartPreview,

      tax,
      subTotal,
      total,

      isFormLoading,
      isLoading,

      status,
      message,

      metaData,
      filterData,

      productData,
      productDataSelected,

      addItem,
      removeItem,
      changeQuantity,
      calculateTotalPriceItem,
      calculateTotalPrice,

      createTransaction,
      resetForm,

      getProduct,

      searchFilter,
      showToast,

      pingSignal,
      timer,
      onError,
    }
  },
  data: () => ({}),
  computed: {
    headerMode: {
      get() {
        return this.$store.getters['global/headerMode']
      },
      set(val) {
        return this.$store.commit('global/setHeaderMode', val)
      },
    },
    headerData: {
      get() {
        return this.$store.getters['global/headerData']
      },
      set(val) {
        return this.$store.commit('global/setHeaderData', val)
      },
    },
  },
  methods: {},
}
</script>
