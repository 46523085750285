<template>
  <section id="content" class="flex flex-col">
    <p>InsightsPage</p>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import Button from '@/components/button/Button.vue'
import CardOne from '@/components/card/CardOne.vue'

// Svgs
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'
import CircleLines from '@/assets/svg/circle-lines.svg'
import BorderLines from '@/assets/svg/border-1.svg'
// Services
import ServiceForSales from '@/assets/svg/services/for-sales.svg'
import ServiceForMarketing from '@/assets/svg/services/for-marketing.svg'
import ServiceForSupplyChain from '@/assets/svg/services/for-supply-chain.svg'
// Offers
import OfferSalesMeasurement from '@/assets/svg/offers/discount.svg'
import OfferInStoreAvailability from '@/assets/svg/offers/store.svg'
import OfferPricePromotion from '@/assets/svg/offers/price-coin.svg'

export default {
  name: 'InsightsPage',
  components: {
    Button,
    CardOne,
    ArrowRightIcon,
    CircleLines,
    BorderLines,
    ServiceForSales,
    ServiceForMarketing,
    ServiceForSupplyChain,
    OfferSalesMeasurement,
    OfferInStoreAvailability,
    OfferPricePromotion,
  },
  mixins: [globalMixin],
  data: () => ({
    services: [
      {
        id: 1,
        title: 'Solution for Sales',
        description:
          'Improve your sales effectiveness and increase revenue with our data and analytics solutions for sales. See sales velocities, inventory levels and competitor positions in real-time at the level of detail you need.',
        icon: 'FOR_SALES',
        routeName: '/',
      },
      {
        id: 2,
        title: 'Solution for Marketing',
        description:
          'Turbo-charge your marketing activities by harnessing the power of real-time analytics and feedback from your biggest channel. Real-time feedback on market share, sales, promotion, new product, advertising effectiveness and much more.',
        icon: 'FOR_MARKETING',
        routeName: '/',
      },
      {
        id: 3,
        title: 'Solution for Supply Chain',
        description:
          'Increase the effectiveness of your Supply Chain to lower inventories and improve service levels with real time inventory and sales data from your largest and most profitable market segment.',
        icon: 'FOR_SUPPLY_CHAIN',
        routeName: '/',
      },
    ],
  }),
  methods: {},
}
</script>
