import axios, { routePathAPI } from '@/plugins/axios.js'
import axiosPayment, { routePathPaymentAPI } from '@/plugins/axios-payment.js'

// initial state
const state = () => ({
  paymentType: {
    icon: '',
    name: '',
    channel_code: '',
    channel_type: '',
    required_number: 0,
    admin_fee: 0,
  },
  allowCashPayment: false,
  allowBrizziPayment: false,
  hideCashPayment: true,
})

// getters
const getters = {
  paymentTypeGetter(state) {
    return state.paymentType
  },
  allowCashPaymentGetter(state) {
    return state.allowCashPayment
  },
  allowBrizziPaymentGetter(state) {
    return state.allowBrizziPayment
  },
  hideCashPaymentGetter(state) {
    return state.hideCashPayment
  },
}

// actions
const actions = {
  setAllowCashPayment({ state, commit }, payload) {
    commit('SET_ALLOW_CASH_PAYMENT', payload)
  },
  resetAllowCashPayment({ state, commit }) {
    commit('RESET_ALLOW_CASH_PAYMENT')
  },
  setAllowBrizziPayment({ state, commit }, payload) {
    commit('SET_ALLOW_BRIZZI_PAYMENT', payload)
  },
  resetAllowBrizziPayment({ state, commit }) {
    commit('RESET_ALLOW_BRIZZI_PAYMENT')
  },
  setHideCashPayment({ state, commit }, payload) {
    commit('SET_HIDE_CASH_PAYMENT', payload)
  },
  resetHideCashPayment({ state, commit }) {
    commit('RESET_HIDE_CASH_PAYMENT')
  },
  setPaymentType({ state, commit }, payload) {
    commit('SET_PAYMENT_TYPE', payload)
  },
  resetPaymentType({ state, commit }) {
    commit('RESET_PAYMENT_TYPE')
  },
  changeMethod({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}payment/change-method`, {
          transaction_id: payload.transaction_id,
          payment_type: payload.payment_type,
          payment_channel_code: payload.payment_channel_code ?? null,
          phone_number: payload.phone_number ?? null,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  paymentChannelList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosPayment
        .get(
          `${routePathPaymentAPI()}payment-channels/${
            payload.instituteId
          }?take=50` // temporary solve the pagination problem
        )
        .then((response) => {
          const responseData = response.data.data
          if (response.status === 200 && responseData.error === undefined) {
            const byType = responseData.data.reduce(function (filtered, item) {
              if (item.is_active) {
                filtered[item.channel_type] = filtered[item.channel_type] || []
                filtered[item.channel_type].push(item)
              }
              return filtered
            }, Object.create(null))

            resolve({
              status: response.status,
              message: 'success',
              data: byType,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  channelSteps({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosPayment
        .get(
          `${routePathPaymentAPI()}payment-channel-steps?payment_channel_id=${
            payload.payment_channel_id
          }`
        )
        .then((response) => {
          const responseData = response.data.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  paymentMethodList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}payment/channel/`)
        .then((response) => {
          const responseData = response.data.data
          if (response.status === 200 && responseData.error === undefined) {
            const byType = responseData.data.reduce(function (filtered, item) {
              filtered[item.channel_type] = filtered[item.channel_type] || []
              filtered[item.channel_type].push(item)
              return filtered
            }, Object.create(null))

            resolve({
              status: response.status,
              message: 'success',
              data: byType,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  topup({ state, commit }, payload) {
    const formData = new FormData()
    formData.append('channel_code', payload.channelCode)
    formData.append('amount', payload.amount)
    formData.append('phone_number', payload.phoneNumber)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}payment/topup/${payload.paymentType}`,
          formData,
          config
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_ALLOW_CASH_PAYMENT(state, isAllowed) {
    state.allowCashPayment = isAllowed
  },
  RESET_ALLOW_CASH_PAYMENT(state) {
    state.allowCashPayment = false
  },
  SET_ALLOW_BRIZZI_PAYMENT(state, isAllowed) {
    state.allowBrizziPayment = isAllowed
  },
  RESET_ALLOW_BRIZZI_PAYMENT(state) {
    state.allowBrizziPayment = false
  },
  SET_HIDE_CASH_PAYMENT(state, isHide) {
    state.hideCashPayment = isHide
  },
  RESET_HIDE_CASH_PAYMENT(state) {
    state.hideCashPayment = true
  },
  SET_PAYMENT_TYPE(state, paymentType) {
    state.paymentType = paymentType
  },
  RESET_PAYMENT_TYPE(state, paymentType) {
    state.paymentType = {
      icon: '',
      name: '',
      channel_code: '',
      channel_type: '',
      required_number: 0,
      admin_fee: 0,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
