<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          OUR SERVICES
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Solutions for Supply Chain
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Keep track of the data you need to keep excelling the growth of your
          company. Get the
        </p>
        <p class="mt-2 text-xs text-gray-400">
          latest insights through our platform and get your implementation plans
          effectively
        </p>
        <p class="mt-2 text-xs text-gray-400">done.</p>
      </div>

      <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img src="@/assets/image/home-1.png" class="w-full rounded-xl" />
        </div>
      </div>

      <div class="flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informations" :key="item.id" class="flex w-full">
          <CardThree :title="item.title" :description="item.description" />
        </div>
      </div>

      <!-- Offers -->
      <div class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:pb-16">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            WHAT WE OFFER
          </p>
        </div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col flex-wrap px-6 md:flex-row lg:px-32">
        <div
          v-for="item in offers"
          :key="item.id"
          class="flex w-full p-2 md:w-1/2 lg:p-8"
        >
          <CardTwo :title="item.title" :description="item.description">
            <OfferUser v-if="item.icon === 'USER'" class="h-10" />
          </CardTwo>
        </div>
      </div>

      <div class="mt-20 flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informationsTwo" :key="item.id" class="flex w-full">
          <CardThree
            :title="item.title"
            :description="item.description"
            :show-icon="false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferUser from '@/assets/svg/offers/user.svg'

export default {
  name: 'ServiceSolutionForSupplyChainPage',
  components: {
    CardTwo,
    CardThree,
    OfferUser,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Actionable Real Time Information',
        description:
          'The Traditional channel consisting of hundreds of thousands of small stores is by far your single largest channel yet its extremely difficult to obtain actionable and timely supply chain information from it. Gromura’s solutions for Supply Chain gives you real-time sales and inventory data figures to track daily sales, in-store availability and overall channel effectiveness by distribution channel whether direct or indirect. Make your demand planning, distribution planning and in-store availability far more effective by using our unique historical and predictive analytical data and toolset.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'The Gromura Advantage',
        description:
          'We collect and track real-time sales data from tens of thousands of traditional retailers and make this information available to you to consume in real-time either through links to your existing analytics solutions or using our insight and analytics workbench. Our unique platform allows you not only to analyze past data but connect in real-time with small retailers to conduct real-time experiments, surveys, and sentiment analysis. Obtain the feedback and information you need in real-time to make your marketing much more effective and generate a much higher return on marketing spend.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Supply Chain Leader',
        description:
          'Monitor the effectiveness of your supply chain down to the crucial ‘last mile’ of the traditional channel.  use the data from Gromura to more effectively plan and manage your Supply Chain activities to improve service levels at the store level and to lower costs.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'S&OP Leader',
        description:
          'Use the data from the Gromura platform to make your S&OP planning much more effective by utilizing the trend and actual data in the Gromura platform to more effectively plan supply/demand decisions, promotional campaigns, and new product launches.',
        icon: 'USER',
      },
      {
        id: 3,
        title: 'Distribution Manager',
        description:
          'Gromura’s inventory and sales velocity data can be used to more effectively plan and manage your distribution channel and assets to ensure the highest service levels at the lowest cost.  Effectively manage your channel partners or view new direct delivery options by using our real time data to plan, simulate and act effectively.',
        icon: 'USER',
      },
      {
        id: 4,
        title: 'Transportation Manager',
        description:
          'Incorporate our data into your transportation planning to better prioritize and schedule routes, take advantage of reverse logistics opportunities and better manage returnable packaging transportation.',
        icon: 'USER',
      },
    ],
  }),
  methods: {},
}
</script>
