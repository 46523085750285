import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

const helpers = {
  serializeObjectToQueryParam(obj) {
    var str = []
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    return str.join('&')
  },
  cutText(text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  formatDate(date, format) {
    return dayjs(date).format(format)
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  formatCurrency(number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, '')
      const rest = formattedNumber.length % 3
      let currency = formattedNumber.substr(0, rest)
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g)
      let separator

      if (thousand) {
        separator = rest ? '.' : ''
        currency += separator + thousand.join('.')
      }

      return currency
    } else {
      return ''
    }
  },
  timeAgo(time) {
    const date = new Date((time || '').replace(/-/g, '/').replace(/[TZ]/g, ' '))
    const diff = (new Date().getTime() - date.getTime()) / 1000
    const dayDiff = Math.floor(diff / 86400)

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format('MMMM DD, YYYY')
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && 'just now') ||
          (diff < 120 && '1 minute ago') ||
          (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
          (diff < 7200 && '1 hour ago') ||
          (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
      (dayDiff === 1 && 'Yesterday') ||
      (dayDiff < 7 && dayDiff + ' days ago') ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
    )
  },
  diffTimeByNow(time) {
    const startDate = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss').toString())
    const endDate = dayjs(dayjs(time).format('YYYY-MM-DD HH:mm:ss').toString())

    const duration = dayjs.duration(endDate.diff(startDate))
    const milliseconds = Math.floor(duration.asMilliseconds())

    const days = Math.round(milliseconds / 86400000)
    const hours = Math.round((milliseconds % 86400000) / 3600000)
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000)
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    )

    if (seconds < 30 && seconds >= 0) {
      minutes += 1
    }

    return {
      days: days.toString().length < 2 ? '0' + days : days,
      hours: hours.toString().length < 2 ? '0' + hours : hours,
      minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
      seconds: seconds.toString().length < 2 ? '0' + seconds : seconds,
    }
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomNumbers(from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  toRGB(colors) {
    const tempColors = Object.assign({}, colors)
    const rgbColors = Object.entries(tempColors)
    for (const [key, value] of rgbColors) {
      if (typeof value === 'string') {
        if (value.replace('#', '').length == 6) {
          const aRgbHex = value.replace('#', '').match(/.{1,2}/g)
          tempColors[key] = (opacity = 1) =>
            `rgb(${parseInt(aRgbHex[0], 16)} ${parseInt(
              aRgbHex[1],
              16
            )} ${parseInt(aRgbHex[2], 16)} / ${opacity})`
        }
      } else {
        tempColors[key] = helpers.toRGB(value)
      }
    }
    return tempColors
  },
  extractFilename(dispotition, defaultName) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(dispotition)
    let filename = defaultName
    if (matches && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
    return filename
  },
  roleCanManage(roles, targets) {
    let managed = false
    for (const role of roles) {
      for (const target of targets) {
        if (role === target) {
          managed = true
        }
      }
    }
    return managed
  },
  changeQuantity: (product, bonus, added) => {
    if (bonus === true) {
      if (added === true) {
        if (product.quantity_bonus === null) {
          product.quantity_bonus = 1
        } else {
          product.quantity_bonus = product.quantity_bonus + 1
        }
      } else {
        if (product.quantity_bonus !== 0) {
          product.quantity_bonus = product.quantity_bonus - 1
        }
      }
    } else {
      if (added === true) {
        if (product.quantity === null) {
          product.quantity = 1
        } else {
          product.quantity = product.quantity + 1
        }
      } else {
        if (product.quantity !== 0) {
          product.quantity = product.quantity - 1
        }
      }
    }

    // calculateTotalPriceItem(product)
    return product
  },
  calculateTotalPriceItem: (product) => {
    if (product.quantity > product.quantity_fixed) {
      product.quantity = product.quantity_fixed
    }
    // if (product.quantity_bonus > product.quantity_bonus_fixed) {
    //   product.quantity_bonus = product.quantity_bonus_fixed
    // }
    product.total_price =
      product.quantity * product.price - product.price_discount

    // calculateTotalPrice()
    return product
  },
  calculateTotalPrice: (transaction) => {
    let totalPrice = 0
    transaction.items.forEach((item) => {
      totalPrice += item.quantity * item.price
    })
    // if (transaction.tax.toString() !== '0') {
    //   subTotal.value = totalPrice - Math.round(totalPrice / transaction.tax)
    // } else {
    //   subTotal.value = totalPrice
    // }
    // total.value = totalPrice

    const subTotal = totalPrice;
    const total = totalPrice;

    return { subTotal, total }
  },
  formatPrice: (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  },
  customRanges: () => {
    const ranges = {
      'Hari ini': [new Date(), new Date()],
      'Kemarin': [
        new Date(dayjs().add(-1, 'days')),
        new Date(dayjs().add(-1, 'days')),
      ],
      'Minggu ini': [
        new Date(dayjs().startOf('week').add(1, 'days')),
        new Date(dayjs().endOf('week').add(1, 'days')),
      ],
      'Minggu lalu': [
        new Date(dayjs().add(-1, 'week').startOf('week').add(1, 'days')),
        new Date(dayjs().add(-1, 'week').endOf('week').add(1, 'days')),
      ],
      'Bulan ini': [
        new Date(dayjs().startOf('month')),
        new Date(dayjs().endOf('month')),
      ],
      'Bulan lalu': [
        new Date(dayjs().add(-1, 'month').startOf('month')),
        new Date(dayjs().add(-1, 'month').endOf('month')),
      ],
      '3 Bulan': [
        new Date(dayjs().add(-2, 'month').startOf('month')),
        new Date(dayjs().endOf('month')),
      ]
    }
    return ranges
  },
  formattedDate(value, format) {
    if (!value) return ''

    let defaultFormat = 'DD MMMM YYYY'
    if (format !== null && format !=='') {
      defaultFormat = format
    }

    const formattedDate = dayjs(value).format('DD MMMM YYYY')
    return formattedDate
  },
  superRoles() {
    return [
      'SUPERADMIN',
      'CEO',
      'HEAD_TECHNOLOGY',
      'HEAD_OPERATION',
      'HEAD_SALES',
      'HEAD_FINANCE',
    ]
  },
}

const install = (app) => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }
