<template>
  <section
    id="content"
    class="flex h-screen flex-col items-center justify-center px-12 pt-20 pb-20"
  >
    <div class="w-full pb-5">
      <NotFoundIcon class="mx-auto" />
    </div>
    <p
      class="text-center text-2xl font-black leading-9 tracking-tight text-gray-700 md:leading-10"
    >
      {{ $t('pageNotFound') }}
    </p>
    <p
      class="py-7 px-4 text-center text-lg font-normal leading-9 tracking-tight text-gray-700 md:leading-9"
    >
      {{ $t('pageNotFoundDesc') }}
      <span class="text-gray-400">{{ $t('pageNotFoundSubDesc') }}</span>
    </p>
    <div class="flex px-8 py-8">
      <div class="inline-flex rounded-md shadow">
        <router-link
          to="/"
          class="bg-ink-primary text-ink-light inline-flex items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium leading-6 opacity-90 transition duration-150 ease-in-out hover:opacity-100 focus:outline-none"
        >
          {{ $t('back_to_home') }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import NotFoundIcon from '@/assets/svg/404.svg'

export default {
  name: 'NotFoundPage',
  components: {
    NotFoundIcon,
  },
}
</script>
