import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({
  isUserLoggedIn: false,
  needVerifyStatus: false,
  forgotPinCode: '',
  phoneNumber: '',
  dialCode: '',
})

// getters
const getters = {
  isUserLoggedIn(state) {
    if (state.isUserLoggedIn === false) {
      return (
        localStorage.getItem('gromura.accessToken') !== null &&
        localStorage.getItem('gromura.refreshToken') !== null
      )
    }
    return state.isUserLoggedIn
  },
  accessToken(state) {
    return localStorage.getItem('gromura.accessToken') || null
    // return state.isUserLoggedIn
  },
  tokenType(state) {
    return localStorage.getItem('gromura.tokenType') || 'Bearer'
    // return state.isUserLoggedIn
  },
  refreshToken(state) {
    return localStorage.getItem('gromura.refreshToken') || null
    // return state.isUserLoggedIn
  },
  activeEmail(state) {
    return localStorage.getItem('gromura.email') || null
  },
  needInputGroupGetter(state) {
    return localStorage.getItem('gromura.needInputGroup')
  },
  registerDataGetter(state) {
    return localStorage.getItem('gromura.registerData') !== null
      ? JSON.parse(localStorage.getItem('gromura.registerData'))
      : {}
  },
  activePhone(state) {
    return state.phoneNumber || ''
  },
  activeDialCode(state) {
    return state.dialCode || ''
  },
  activePhoneWithDial(state) {
    return state.dialCode && state.phoneNumber
      ? state.dialCode + '' + state.phoneNumber
      : ''
  },
  forgotPinCode(state) {
    return state.forgotPinCode || null
  },
  needVerifyStatusGetter(state) {
    return state.needVerifyStatus
  },
}

// actions
const actions = {
  setActivePhone({ commit }, payload) {
    commit('SET_ACTIVE_PHONE', payload)
  },
  setActiveDialCode({ commit }, payload) {
    commit('SET_ACTIVE_DIAL_CODE', payload)
  },
  setForgotPinCode({ commit }, payload) {
    commit('SET_FORGOT_PIN_CODE', payload)
  },
  updateUserState({ commit, state }, loggedIn) {
    return commit('SET_IS_USER_LOGGED_IN', loggedIn)
  },
  needVerifyStatus({ commit, state }, status) {
    return commit('SET_NEED_VERIFY_STATUS', status)
  },
  needInputGroup({ commit, state }, inputGroupStatus) {
    return commit('SET_NEED_INPUT_GROUP', inputGroupStatus)
  },
  resetNeedInputGroup({ commit, state }) {
    return commit('REMOVE_NEED_INPUT_GROUP')
  },
  registerData({ commit, state }, registerData) {
    return commit('SET_REGISTER_DATA', registerData)
  },
  resetRegisterData({ commit, state }) {
    return commit('REMOVE_REGISTER_DATA')
  },
  forgotPassword({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}auth/forgot-password/`, {
          phone_number: payload.phone_number,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200) {
            commit('SET_ACTIVE_PHONE', payload.phone_number)
            resolve({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verifyResetPassword({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}auth/verify-token-reset/`, {
          verification_code: payload.code,
          phone: payload.phone,
        })
        .then((response) => {
          if (response.status === 200) {
            commit('SET_FORGOT_PIN_CODE', payload.code)
            resolve({
              status: response.status,
              useLocalization: true,
              message: 'verify_code_true',
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resetPassword({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}auth/reset-password/`, {
          phone: payload.phoneNumber,
          pin: payload.pin,
          verification_code: payload.verificationCode,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: response.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit, dispatch }, payload) {
    commit('SET_IS_USER_LOGGED_IN', false)
    commit('REMOVE_TOKEN_TYPE')
    commit('REMOVE_ACCESS_TOKEN')
    commit('REMOVE_REFRESH_TOKEN')
    commit('REMOVE_ACTIVE_EMAIL')
    commit('REMOVE_ACTIVE_PHONE')

    commit('REMOVE_NEED_INPUT_GROUP')
    commit('REMOVE_REGISTER_DATA')
    return true
  },
  loginOrRegister({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}user/login/`, {
          institute_id: payload.instituteId,
          phone_number: payload.phone_number,
          role: 'user', // user ?? tenant
          grant_type: import.meta.env.GRANT_TYPE,
          client_id: import.meta.env.CLIENT_ID,
          client_secret: import.meta.env.CLIENT_SECRET,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'otp_sent',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resendOTP({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}user/resend-otp/`, {
          phone_number: payload.phone_number.startsWith('+')
            ? payload.phone_number
            : '+' + payload.phone_number,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'otp_sent',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: response.message,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verify({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const headers = {}
      const guestId = localStorage.getItem('gromura.guestId') || null
      if (guestId !== null) {
        headers['x-guest-id'] = `${guestId}`
      }
      axios
        .post(
          `${routePathAPI()}user/verify-otp/`,
          {
            phone_number: payload.phone_number.startsWith('+')
              ? payload.phone_number
              : '+' + payload.phone_number,
            otp_code: payload.code,
          },
          {
            headers,
          }
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            const accessToken = responseData.access_token
            const refreshToken = responseData.refresh_token
            const tokenType = responseData.token_type
            // const needInputGroup = responseData.need_input_group
            // const expiresIn = responseData.expires_in

            // set token to the header
            if (accessToken !== null) {
              axios.defaults.headers.common[
                'Authorization'
              ] = `${tokenType} ${accessToken}`
            }

            commit('SET_ACCESS_TOKEN', { accessToken, tokenType })
            commit('SET_REFRESH_TOKEN', refreshToken)
            commit('SET_IS_USER_LOGGED_IN', true)
            // if (needInputGroup === true) {
            //   commit('SET_NEED_INPUT_GROUP', needInputGroup)
            // }

            resolve({
              status: response.status,
              message: 'you_have_successfully_logged_in',
              useLocalization: true,
              data: responseData,
            })
          } else if (responseData.error !== undefined) {
            reject({
              status: response.status,
              message: 'wrong_phone_or_pin',
              useLocalization: true,
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'wrong_phone_or_pin',
              useLocalization: true,
              data: response.data,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_ACCESS_TOKEN(state, { accessToken, tokenType }) {
    localStorage.setItem('gromura.accessToken', accessToken)
    localStorage.setItem('gromura.tokenType', tokenType)
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    localStorage.setItem('gromura.refreshToken', refreshToken)
  },
  SET_ACTIVE_EMAIL(state, email) {
    localStorage.setItem('gromura.email', email)
  },
  SET_ACTIVE_PHONE(state, phoneNumber) {
    state.phoneNumber = phoneNumber
  },
  SET_ACTIVE_DIAL_CODE(state, dialCode) {
    state.dialCode = dialCode
  },
  SET_IS_USER_LOGGED_IN(state, isUserLoggedIn) {
    state.isUserLoggedIn = isUserLoggedIn
  },
  SET_NEED_INPUT_GROUP(state, needInputGroup) {
    localStorage.setItem('gromura.needInputGroup', needInputGroup)
  },
  SET_REGISTER_DATA(state, registerData) {
    localStorage.setItem('gromura.registerData', JSON.stringify(registerData))
  },
  REMOVE_ACCESS_TOKEN(state) {
    localStorage.removeItem('gromura.accessToken')
  },
  REMOVE_TOKEN_TYPE(state) {
    localStorage.removeItem('gromura.tokenType')
  },
  REMOVE_REFRESH_TOKEN(state) {
    localStorage.removeItem('gromura.refreshToken')
  },
  REMOVE_ACTIVE_EMAIL(state) {
    localStorage.removeItem('gromura.email')
  },
  REMOVE_NEED_INPUT_GROUP(state) {
    localStorage.removeItem('gromura.needInputGroup')
  },
  REMOVE_REGISTER_DATA(state) {
    localStorage.removeItem('gromura.registerData')
  },
  REMOVE_ACTIVE_PHONE(state) {
    state.phoneNumber = ''
  },
  REMOVE_ACTIVE_DIAL(state) {
    state.dialCode = ''
  },
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('gromura.userInfo')) ||
      state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('gromura.userInfo', JSON.stringify(userInfo))
  },
  SET_FORGOT_PIN_CODE(state, code) {
    state.forgotPinCode = code
  },
  SET_NEED_VERIFY_STATUS(state, status) {
    state.needVerifyStatus = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
