<template>
  <section id="content" class="container mx-auto flex flex-col items-center px-6 md:px-0">
    <div
      class="container flex flex-col pb-20 pt-10 lg:mx-auto lg:pb-32 lg:pt-20"
    >
      <div class="flex w-full flex-col-reverse lg:flex-row lg:px-0">
        <div
          class="flex w-full flex-col pr-0 pt-12 md:space-y-2 md:pr-10 lg:w-1/2 lg:pt-0"
        >
          <p class="text-md text-gray-400 md:text-lg">Gromura</p>
          <div class="hidden space-y-0 lg:flex lg:flex-col lg:space-y-4">
            <p class="text-2xl text-black lg:text-4xl">
              Kebutuhan Warung Anda Menjadi Lebih Dekat.
            </p>
            <p class="text-2xl text-black lg:text-4xl">
              Harga Terbaik dengan Keuntungan Maksimal!
            </p>
            <!-- <p class="text-2xl text-black lg:text-4xl">Channel</p> -->
          </div>
          <div class="flex lg:hidden">
            <p class="text-2xl text-black">
              Kebutuhan Warung Anda Menjadi Lebih Dekat. Harga Terbaik dengan
              Keuntungan Maksimal!
            </p>
          </div>
          <div class="flex w-full flex-col md:flex-row md:items-center">
            <div class="relative flex items-center">
              <CircleLines />
              <div class="absolute">
                <Button title="Pesan Sekarang" @click="orderNow">
                  <ArrowRightIcon class="ml-2 h-3 text-white" />
                </Button>
              </div>
            </div>
            <!-- <div class="mt-4 flex md:mt-0 md:pl-8">
              <p
                class="text-gromura-primary cursor-pointer text-sm underline underline-offset-4 md:text-base"
                @click="openRoute('partnership')"
              >
                See Partnership Plans
              </p>
            </div> -->
          </div>
        </div>
        <div class="flex w-full lg:w-1/2">
          <div class="relative flex w-full items-start">
            <img
              src="@/assets/image/welcome-header.png"
              class="max-h-min w-full rounded-xl"
            />
            <!-- <div
              class="bg-kira-primary absolute -ml-5 -mb-6 h-12 w-12 rounded-lg shadow-xl md:h-20 md:w-20 lg:-mb-6"
            ></div> -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="container flex w-full flex-col pb-20 lg:mx-auto lg:flex-row lg:px-0"
    >
      <div class="flex w-full lg:w-5/12 lg:px-0 lg:pr-20">
        <div class="-mt-2 w-full">
          <img
            src="@/assets/image/vision_mission.png"
            class="w-full rounded-xl"
          />
        </div>
      </div>
      <div
        class="mt-10 flex w-full flex-col pr-4 pb-10 md:pr-6 md:pb-0 lg:mt-0 lg:w-7/12 lg:px-0 lg:pr-20 xl:pr-24"
      >
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          <!-- KEUNTUNGAN MENJADI MITRA KAMI -->
          AYO MULAI BERJUALAN
        </p>
        <div class="mt-4 space-y-3">
          <p class="text-xl text-black md:text-2xl">
            <!-- Mengubah Wawasan Dalam Berbisnis Retail, Toko, Warung & Pasokan Rantai Produk -->
            Menjadikan kami bagian dari mitra warung anda untuk memajukan
            perekonomian warung di Indonesia
          </p>
        </div>
        <!-- <div class="mt-4 flex lg:hidden">
          <p class="text-xl text-black md:text-2xl">
            Wawasan yang Mengubah Permainan untuk Pemasaran, Penjualan & Rantai Pasok
          </p>
        </div> -->
        <div class="mt-4 flex flex-col">
          <p class="text-sm leading-8 text-gray-400">
            <!-- <span class="text-gromura-secondary">UMKM</span> saat ini menjadi penopang ekomoni paling mendasar di Indonesia. Hal ini terbukti UMKM menjadi sektor yang paling stabil dimasa pandemi Covid-19. Maka saat ini penting bagi Gromura untuk melakukan serta mendukung stabilitas UMKM di Indonesia. -->
            <span class="text-gromura-secondary"
              >Dengan fokus pada tujuan utama kami</span
            >
            yaitu membangun perekonomian warung indonesia lewat jalur distribusi
            yang terbaik dengan harga yang kompetitif, diharapkan dapat menjadi
            solusi di tengah persaingan di dunia industri yang sangat pesat ini.
          </p>
          <!-- <p class="mt-3 text-sm leading-8 text-gray-400">
            Join us as an early bird priority partner to have privileged access:
          </p>
          <p class="text-sm leading-8 text-gray-400">
            - Exclusive access to data and insights
          </p>
          <p class="text-sm leading-8 text-gray-400">
            - Discounts on advertising and promotions
          </p>
          <p class="text-sm leading-8 text-gray-400">
            - Exclusive input on the development of the platform
          </p>
          <p class="mt-3 text-sm leading-8 text-gray-400">
            Click below to learn more about how our early access partnership
            program can significantly benefit your business over the coming
            months and years!
          </p> -->
        </div>

        <div class="mt-4 flex w-full justify-end">
          <div
            class="flex cursor-pointer flex-row items-center justify-end pr-4 md:pr-0"
          >
            <BorderLines />
            <p class="text-gromura-secondary mx-2 text-sm leading-8">
              Pelajari Lebih Lanjut
            </p>
            <ArrowRightIcon class="ml-2 h-3 text-gray-700" />
          </div>
        </div>
      </div>
    </div>

    <div class="container flex flex-col pb-20 lg:mx-auto lg:pb-32">
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          LAYANAN KAMI
        </p>

        <p class="text-md mt-4 self-center text-black md:text-lg lg:text-2xl">
          <!-- Rantai Pasok adalah Kunci dari bisnis UMKM disektor ini. Maka kami Gromura Hadir dengan Solusi dalam Rantai Pasokan tersebut dengan didukung strategi penjualan yang memberikan solusi. -->
          Kami berfokus pada produk Consumer Good dan sektor kebutuhan pokok
          masyarakat
        </p>
        <p class="md:text-md mt-12 self-center text-sm text-black">
          <!-- Informasi adalah Kunci terbaik dalam memahami dan membangun Jalur terbaik dalam perencanaan masa depan suatu bisnis yang akan mitra dan perusahaan kami. -->
          Dengan lebih spesifik kepada produk yang memiliki perputaran cepat dan
          lebih produktif lagi
        </p>
        <p class="md:text-md mt-2 self-center text-sm text-black">
          Tidak hanya itu, kami juga membangun program digitalisasi warung ke
          arah warung modern dengan bantuan teknologi yg kami berikan
        </p>

        <p class="mt-12 text-xs text-gray-400 md:text-sm">
          Jadilah bagian dari mitra kami guna membuat sejarah baru bagi anda dan
          kita semua.
        </p>
        <!-- <p class="mt-2 text-xs text-gray-400">
          companies future. Be the first in the race with our platform.
        </p> -->
      </div>

      <!-- Services -->
      <div class="mt-10 flex w-full flex-col text-center md:flex-row md:px-16 ">
        <div
          v-for="item in services"
          :key="item.id"
          class="flex w-full p-2 md:w-1/3 md:p-6 xl:p-8"
        >
          <CardOne
            :title="item.title"
            :description="item.description"
            @on-click="openRoute(item.routeName)"
          >
            <ServiceForSales v-if="item.icon === 'FOR_SALES'" class="h-20" />
            <ServiceForMarketing
              v-if="item.icon === 'FOR_MARKETING'"
              class="h-20"
            />
            <ServiceForSupplyChain
              v-if="item.icon === 'FOR_SUPPLY_CHAIN'"
              class="h-20"
            />
          </CardOne>
        </div>
      </div>

      <div class="container mt-20 flex flex-col pb-12 lg:mx-auto lg:pb-16">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            APA YANG KAMI TAWARKAN
          </p>
        </div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col md:flex-row md:px-16 ">
        <div
          v-for="item in offers"
          :key="item.id"
          class="flex w-full p-2 md:w-1/3 md:p-6 xl:p-8"
        >
          <CardTwo :title="item.title">
            <OfferSalesMeasurement v-if="item.icon === 'SALES'" class="h-10" />
            <OfferInStoreAvailability
              v-if="item.icon === 'AVAILABILITY'"
              class="h-10"
            />
            <OfferPricePromotion
              v-if="item.icon === 'PRICE_PROMOTION'"
              class="h-10"
            />
          </CardTwo>
        </div>
      </div>

      <!-- <div class="container mt-20 flex flex-col pb-8 lg:mx-auto lg:pb-12">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            MITRA BRAND KAMI
          </p>
        </div>
      </div>

      <div class="mb-20 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
        <div
          v-for="item in mitras"
          :key="item.id"
          class="flex w-full h-12 md:h-24 lg:h-32"
        >
          <img :src="item.src" class="w-full" />
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import Button from '@/components/button/Button.vue'
import CardOne from '@/components/card/CardOne.vue'
import CardTwo from '@/components/card/CardTwo.vue'

// Svgs
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'
import CircleLines from '@/assets/svg/circle-lines.svg'
import BorderLines from '@/assets/svg/border-1.svg'
// Services
import ServiceForSales from '@/assets/svg/services/for-sales.svg'
import ServiceForMarketing from '@/assets/svg/services/for-marketing.svg'
import ServiceForSupplyChain from '@/assets/svg/services/for-supply-chain.svg'
// Offers
import OfferSalesMeasurement from '@/assets/svg/offers/discount.svg'
import OfferInStoreAvailability from '@/assets/svg/offers/store.svg'
import OfferPricePromotion from '@/assets/svg/offers/price-coin.svg'

export default {
  name: 'HomePage',
  components: {
    Button,
    CardOne,
    CardTwo,
    ArrowRightIcon,
    CircleLines,
    BorderLines,
    ServiceForSales,
    ServiceForMarketing,
    ServiceForSupplyChain,
    OfferSalesMeasurement,
    OfferInStoreAvailability,
    OfferPricePromotion,
  },
  mixins: [globalMixin],
  data: () => ({
    services: [
      {
        id: 1,
        title: 'Solusi untuk Mitra',
        description:
          'Tingkatkan efektivitas penjualan dan tingkatkan pendapatan and bersama kami.',
        icon: 'FOR_SALES',
        routeName: 'service-solution-for-sales',
      },
      {
        id: 2,
        title: 'Solusi untuk Brand',
        description:
          'Tingkatkan aktivitas pemasaran Anda dengan memanfaatkan kekuatan analisis data bersama kami.',
        icon: 'FOR_MARKETING',
        routeName: 'service-solution-for-marketing',
      },
      {
        id: 3,
        title: 'Solusi untuk Rantai Pasok',
        description:
          'Tingkatkan efektivitas Rantai Pasok Anda untuk mencapai keuntungan bersama.',
        icon: 'FOR_SUPPLY_CHAIN',
        routeName: 'service-solution-for-supply-chain',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Harga murah tanpa minimal quantity pengambilan',
        description:
          'Gunakan data real-time untuk meningkatkan proses penjualan dan meningkatkan pendapatan. Bandingkan dengan mudah data mendalam dari berbagai geografi dan bahkan waktu dalam sehari.',
        icon: 'SALES',
      },
      {
        id: 2,
        title:
          'Kualitas terjamin, jika ada produk rusak saat proses transaksi, proses retur mudah dan cepat',
        description:
          'Memprediksi pasokan dan permintaan yang dibutuhkan dengan melihat apa yang dibeli pelanggan dan area mana secara real-time. Dan bersiaplah dengan inventaris di wilayah geografis yang tepat.',
        icon: 'AVAILABILITY',
      },
      {
        id: 3,
        title: 'Gratis pengiriman dengan system distribusi yang cepat',
        description:
          'Gunakan platform kami, informasi dapat digunakan untuk menguji berbagai strategi penetapan harga dan invensi perdagangan dan untuk dapat dengan cepat mengakses informasi harga komparatif yang kompetitif untuk merespons dengan cepat pergerakan merk dan produk pesaing.',
        icon: 'PRICE_PROMOTION',
      },
    ],
    mitras: [
      {
        id: 1,
        src: 'https://download.logo.wine/logo/Mayora_Indah/Mayora_Indah-Logo.wine.png',
      },
      {
        id: 2,
        src: 'https://cdn1-production-images-kly.akamaized.net/OsgwSEoDnLwpoojMXAxtFvTdQ64=/1200x900/smart/filters:quality(75):strip_icc():format(webp)/kly-media-production/medias/4252286/original/034125800_1670386825-kino.jpg',
      },
      {
        id: 3,
        src: 'https://images.glints.com/unsafe/glints-dashboard.s3.amazonaws.com/company-logo/789d707865d27a3a2c22d5e81e962db0.jpg',
      },
      {
        id: 4,
        src: 'https://download.logo.wine/logo/Indofood/Indofood-Logo.wine.png',
      },
      {
        id: 5,
        src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Wings_%28Indonesian_company%29_logo.svg/1200px-Wings_%28Indonesian_company%29_logo.svg.png',
      },
      {
        id: 6,
        src: 'https://connect-assets.prosple.com/cdn/ff/HWl2pAeoqzgONvW0ftpZOpI227eBJbgwM5hvedLIRf8/1669794789/public/2022-11/produk-garudafood.jpg',
      },
      {
        id: 7,
        src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Otsuka_Holdings_logo.svg/2560px-Otsuka_Holdings_logo.svg.png',
      },
      {
        id: 8,
        src: 'https://seeklogo.com/images/A/abc-logo-714CDCA40B-seeklogo.com.png',
      },
      {
        id: 9,
        src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Ultrajaya.png/640px-Ultrajaya.png',
      },
      {
        id: 10,
        src: 'https://w7.pngwing.com/pngs/244/597/png-transparent-unilever-hd-logo.png',
      },
      {
        id: 11,
        src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Logo_Official_SidoMuncul._Tbk.svg/2560px-Logo_Official_SidoMuncul._Tbk.svg.png',
      },
      {
        id: 12,
        src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Nabati_brand_logo.svg/1200px-Nabati_brand_logo.svg.png',
      },
    ],
  }),
  methods: {
    async orderNow() {
      window.open
      this.isLoading = true
      window.location.href = `https://app.gromura.com`
    },
  },
}
</script>
