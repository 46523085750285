<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col pb-6 pt-10 lg:mx-auto lg:px-6 lg:pb-16 lg:pt-16"
    >
      <div class="mb-16 flex w-full flex-col items-center text-left md:text-center px-4">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          MARI CARI TAHU
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Misi dan Visi kami
        </p>

        <p class="mt-8 text-xs text-gray-400 md:text-sm">
          Menjadi bagian besar di dalam mata rantai pemasok produk Consumer Good
          secara nasional dan berkontribusi dalam membangun ekonomi warung
          Indonesia
        </p>

        <p class="mt-4 text-xs text-gray-400 md:text-sm">
          Menjaga Mutu dan kualitas, integritas, efektifitas dan efisiensi dalam
          setiap proses distribusi yang dilakukan, serta mengedepankan kepuasan
          dari para pelanggan kami
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-40"></div>
      </div>
    </div>
    <div class="mb-20 flex w-full flex-col">
      <div
        class="vision-mission flex items-center bg-cover bg-center py-12 md:py-24 lg:py-32 xl:py-56"
      >
        <div
          class="z-20 flex w-full flex-col items-center px-6 md:px-12 py-10 md:py-20 lg:px-56"
        >
          <p class="md:text-md self-center text-xl text-white md:text-2xl">
            Tujuan dan Nilai Kami
          </p>
          <ul class="list-disc mt-10 text-xs leading-6 text-white md:text-base md:leading-10">
            <li>
              Membantu perekonomian warung-warung di Indonesia, khususnya di
              sector Consumer Good
            </li>
            <li>
              Mengelola dan mengembangkan bisnis kearah Inovasi dan Kreatifitas
              yang searah dengan kebutuhan pasar
            </li>
            <li>
              Berpartisipasi dengan program pemerintah dalam memajukan dan
              mensejahterakan UMKM nasional
            </li>
          </ul>
          <p
            class="mt-3 text-center text-xs leading-6 text-white md:mt-8 md:text-base md:leading-10"
          ></p>
        </div>
      </div>
    </div>

    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-20 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <QuoteIcon class="w-12" />
        <p
          class="md:text-lg mt-12 self-center text-sm lg:text-xl italic text-black md:text-2xl"
        >
          “Satu-satunya cara untuk menemukan batas-batas kemungkinan adalah
          dengan melampaui batas-batas tersebut menuju hal-hal yang mustahil.”
        </p>
        <p
          class="md:text-md mt-4 self-center text-xl italic text-black md:text-2xl"
        >
          –Arthur C. Clarke
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import QuoteIcon from '@/assets/svg/quote.svg'

export default {
  name: 'ServiceSolutionForMarketingPage',
  components: { QuoteIcon },
  mixins: [globalMixin],
  data: () => ({}),
  methods: {},
}
</script>

<style scoped>
.vision-mission {
  background-image: url('@/assets/image/vision_mission.png');
  background-size: cover;
  box-shadow: inset 0 0 0 2000px #051b1695;
}
</style>
