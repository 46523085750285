import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  callWaiter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        tenant_id: payload.tenant_id,
        request: payload.request,
        table: payload.table,
      }
      axios
        .post(`${routePathAPI()}waiter`, params)
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
