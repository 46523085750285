<template>
  <button
    class="w-max-content inline-flex items-center justify-center rounded-full border border-transparent drop-shadow-2xl transition duration-150 ease-in-out focus:outline-none"
    :class="[buttonClass, customClass !== '' ? customClass : 'px-6 py-3']"
    @click="onClick()"
  >
    <svg
      v-if="isLoading"
      class="mr-3 -ml-1 h-5 w-5 animate-spin"
      :class="svgClass"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <p class="text-sm lg:text-base" v-else>
      {{ title }}
    </p>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonDefault',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    buttonColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    buttonClass: '',
    svgClass: '',
  }),
  mounted() {
    switch (this.buttonColor) {
      case 'primary':
        this.buttonClass =
          'bg-kira-primary opacity-100 hover:opacity-90 text-gromura-light -darker'
        this.svgClass = 'text-gromura-light'
        break
      case 'secondary':
        this.buttonClass =
          'vs-button vs-button-filled vs-button-secondary text-gromura-light'
        this.svgClass = 'text-gromura-light'
        break
      case 'danger':
        this.buttonClass =
          'bg-kira-danger opacity-100 hover:opacity-90 text-gromura-light'
        this.svgClass = 'text-gromura-light'
        break
      case 'white':
        this.buttonClass = 'vs-button vs-button-filled bg-white text-black'
        this.svgClass = 'text-black'
        break
      case 'primary-outline':
        this.buttonClass = 'border-ink-primary text-gromura-primary'
        this.svgClass = 'text-gromura-primary'
        break
      case 'secondary-outline':
        this.buttonClass = 'border-secondary text-secondary'
        this.svgClass = 'text-secondary'
        break
      case 'danger-outline':
        this.buttonClass = 'border-ink-danger text-gromura-danger'
        this.svgClass = 'text-gromura-danger'
        break
      default:
        this.buttonClass = 'bg-gray-300 text-gromura-light'
        this.svgClass = 'text-gromura-light'
        break
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
