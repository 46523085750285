export default {
  language: 'Bahasa',
  appName: 'gromura',
  greeting: 'Halo Dunia!',
  hello: 'Halo',
  location: 'Jakarta',
  home: 'Beranda',
}

// export default id
