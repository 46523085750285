<template>
  <div class="flex h-full w-full flex-col overflow-y-auto bg-white">
    <NavbarDefault />
    <div class="bg-kira-light flex flex-col" @scroll="handleScroll">
      <slot />
    </div>
    <FooterDefault />
  </div>
</template>

<script>
import NavbarDefault from '../components/navbar/NavbarDefault.vue'
import FooterDefault from '../components/footer/FooterDefault.vue'
import globalMixin from '@/mixins/global.js'

export default {
  name: 'DefaultLayout',
  components: { NavbarDefault, FooterDefault },
  mixins: [globalMixin],
  data: () => ({ showMenu: false }),
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toggleNav() {
      this.showMenu = !this.showMenu
    },
    handleScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target

      if (scrollTop > 30) {
        this.openMenu = false
        this.headerMode = 'narrow'
      } else {
        this.headerMode = 'full'
      }
    },
  },
}
</script>
