import axios, { routePathAPI, routePathAPIVersion } from '@/plugins/axios.js'
import axiosOrder, { routePathOrderAPI } from '@/plugins/axios-order.js'

const getHeaders = (initial = {}) => {
  const headers = initial

  const guestId = localStorage.getItem('gromura.guestId') || null
  if (guestId !== null) {
    headers['x-guest-id'] = guestId
  }

  const tokenType = localStorage.getItem('gromura.tokenType') || 'Bearer'
  const accessToken = localStorage.getItem('gromura.accessToken') || null
  if (accessToken !== null) {
    headers['Authorization'] = `${tokenType} ${accessToken}`
  }

  const config = {
    headers,
  }

  return config
}

// initial state
const state = () => ({
  lastTransaction: {},
  totalActiveOrder: 0,
  activePage: 'active', // active || history
})

// getters
const getters = {
  lastTransactionGetter(state) {
    return state.lastTransaction || {}
  },
  totalActiveOrder(state) {
    return state.totalActiveOrder || 0
  },
  activePage(state) {
    return state.activePage
  },
  lastTrancactionId() {
    return localStorage.getItem('gromura.lastTrancactionId') || null
  },
}

// actions
const actions = {
  v2({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const state = payload.state || '' // active | history
      const orderStatus = payload.orderStatus || '' // state[active] = pending | paid | process, state[history]= completed | canceled
      const page = payload.page || 1
      axios
        .get(
          `${routePathAPIVersion()}orders?state=${state}&status=${orderStatus}&page=${page}`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  invoice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const state = axios
        .get(
          `${routePathAPIVersion()}orders/invoice/${
            payload.transactionTenantId
          }`
        )
        .then((response) => {
          resolve({
            status: response.status,
            message: response.message,
            data: response.data,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // active({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     const state = 'active' // active | history
  //     const orderStatus = payload.orderStatus || '' // state[active] = pending | paid | process, state[history]= completed | canceled
  //     const page = payload.page || 1
  //     axios
  //       .get(
  //         `${routePathAPIVersion()}orders?state=${state}&status=${orderStatus}&page=${page}`
  //       )
  //       .then((response) => {
  //         const responseData = response.data.data
  //         resolve({
  //           status: response.status,
  //           message: responseData.message,
  //           data: responseData,
  //         })
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  // history({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     const state = 'history' // active | history
  //     const orderStatus = payload.orderStatus || '' // state[active] = pending | paid | process, state[history]= completed | canceled
  //     const page = payload.page || 1
  //     axios
  //       .get(
  //         `${routePathAPIVersion()}orders?state=${state}&status=${orderStatus}&page=${page}`
  //       )
  //       .then((response) => {
  //         const responseData = response.data.data
  //         resolve({
  //           status: response.status,
  //           message: responseData.message,
  //           data: responseData,
  //         })
  //       })
  //       .catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  v2_detail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderId = payload.id || ''
      axios
        .get(`${routePathAPIVersion()}orders/${orderId}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  v2_detail_tenant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderId = payload.id || ''
      axios
        .get(`${routePathAPIVersion()}orders/tenant/${orderId}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  active({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderStatus = payload.orderStatus || ''
      const page = payload.page || 1
      axios
        .get(
          `${routePathAPI()}order/active/list?status=${orderStatus}&page=${page}`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activeCount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderStatus = payload.orderStatus
      axios
        .get(`${routePathAPI()}order/active/count?status=${orderStatus}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  history({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const page = payload.page || 1
      const orderStatus = payload.status || ''
      axios
        .get(
          `${routePathAPI()}order/history/list?status=${orderStatus}&page=${page}`
        )
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  orders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // categories?use_serializer=0&page=1&per_page=10
      const orderStatus = payload.orderStatus || 'done'
      const page = payload.page || 1
      axios
        .get(`${routePathAPI()}order/list?page=${page}`)
        // .get(`${routePathAPI()}order/list?status=${orderStatus}&page=${page}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  detail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const orderId = payload.id || ''
      axios
        .get(`${routePathAPI()}order/detail/${orderId}`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPI()}order/update/${payload.id}`, {
          status: payload.status,
          notes: '', // bypass notes
        })
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  repeatOrder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}order/repeat-order/${payload.id}`)
        .then((response) => {
          const responseData = response.data
          resolve(responseData)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  cancelAll({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}order/cancel`, payload)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            success: response.success,
            // message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}order/cancel`, payload)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            success: response.success,
            // message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  transactionDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}order/transaction/detail/${payload.id}/`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: response.data.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setLastTransaction({ commit }, payload) {
    commit('SET_LAST_TRANSACTION', payload)
  },
  resetLastTransaction({ commit }) {
    commit('RESET_LAST_TRANSACTION')
  },
  setActivePage({ commit }, payload) {
    commit('SET_ACTIVE_PAGE', payload)
  },
  resetActivePage({ commit }) {
    commit('RESET_ACTIVE_PAGE')
  },
  setLastTransactionId({ commit }, payload) {
    commit('SET_LAST_TRANSACTION_ID', payload)
  },
  resetLastTransactionId({ commit }) {
    commit('RESET_LAST_TRANSACTION_ID')
  },
  setTotalActiveOrder({ commit }, payload) {
    commit('SET_TOTAL_ACTIVE_ORDER', payload)
  },
  sendReview({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}products/${payload.orderId}/review/`, {
          rate: payload.rating,
          review: payload.review,
        })
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // FOR V3 API ORDER
  summaryAPIOrder({ state, commit }, payload) {
    const params = {}
    params.cart_ids = payload.cart_ids ? payload.cart_ids : []
    params.type = 'summary'
    params.payment_type = payload.payment_type
    params.channel_code = payload.channel_code
    params.order_type = payload.order_type
    params.table = payload.table
    params.is_serving_now = payload.is_serving_now
    params.promo_id = payload.promo_id ? payload.promo_id : ''
    params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (payload.unique_code) params.unique_code = payload.unique_code

    const config = getHeaders()

    return new Promise((resolve, reject) => {
      axiosOrder
        .post(`${routePathOrderAPI()}order/summary`, params, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkoutAPIOrder({ state, commit }, payload) {
    const params = {}
    params.cart_ids = payload.cart_ids ? payload.cart_ids : []
    params.type = 'process'
    params.payment_type = payload.payment_type
    params.channel_code = payload.channel_code
    params.order_type = payload.order_type
    params.table = payload.table
    params.is_serving_now = payload.is_serving_now
    params.serving_time = payload.serving_time ? payload.serving_time : ''
    params.promo_id = payload.promo_id
    params.phone_number = payload.phone_number ? payload.phone_number : ''
    params.unique_code = payload.unique_code ? payload.unique_code : ''
    params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return new Promise((resolve, reject) => {
      const config = getHeaders()

      axiosOrder
        .post(`${routePathOrderAPI()}order/checkout`, params, config)
        .then((response) => {
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: response.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_LAST_TRANSACTION(state, transaction) {
    state.lastTransaction = transaction
  },
  RESET_LAST_TRANSACTION(state, transaction) {
    state.lastTransaction = {}
  },
  SET_ACTIVE_PAGE(state, activePage) {
    state.activePage = activePage
  },
  RESET_ACTIVE_PAGE(state) {
    state.activePage = 'active'
  },
  SET_TOTAL_ACTIVE_ORDER(state, total) {
    state.totalActiveOrder = total
  },
  SET_LAST_TRANSACTION_ID(state, transactionId) {
    localStorage.setItem('gromura.lastTrancactionId', transactionId)
  },
  RESET_LAST_TRANSACTION_ID(state) {
    localStorage.removeItem('gromura.lastTrancactionId')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
