import axios, { routePathAPI, routePathAPIVersion } from '@/plugins/axios.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  tenantMenu({ commit }, payload) {
    let url = `${routePathAPI()}tenants/${payload.id}/products`
    if (payload.search) url = url.concat(`?search=${payload.search || ''}`)
    else url = url.concat(`?page=${payload.page || 1}`)
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  tenantList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${routePathAPI()}tenants?instituteId=${payload.instituteId}`
      if (payload.search) url = url.concat(`&search=${payload.search || ''}`)
      else url = url.concat(`&page=${payload.page || 1}`)

      axios
        .get(url)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  tenantAutoOpen({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${routePathAPI()}tenants/auto-open?instituteId=${
        payload.instituteId
      }`
      if (payload.gmt) url = url.concat(`&gmt=${payload.gmt || ''}`)

      axios
        .get(url)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // FOR V3
  tenantListNew({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.institute = payload.instituteId
      params.page = payload.page ? payload.page : 1
      if (payload.q) params.q = payload.q
      if (payload.take) params.take = payload.take

      axios
        .get(`${routePathAPIVersion()}tenants`, { params })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  tenantDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPIVersion()}tenants/${payload.id}`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
