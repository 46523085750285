<template>
  <footer
    class="relative isolate w-full overflow-hidden bg-gray-50 pt-10 md:mt-20 lg:pt-14"
  >
    <div class="flex w-full flex-col px-8 md:mx-auto">
      <div
        class="container mx-auto flex w-full flex-col lg:flex-row lg:items-center lg:justify-between"
      >
        <div
          class="mb-6 flex w-full flex-col justify-start self-start lg:mb-0 lg:w-1/4"
        >
          <div class="flex">
            <img src="@/assets/image/logo.png" class="-ml-1 h-6 md:h-8" />
          </div>
          <div class="mt-8 flex flex-col">
            <p class="text-xs font-bold text-black">
              PT. Karya Kreasi Nasional
            </p>
            <p class="text-xs text-gray-600">Ruko Kutajaya</p>
            <p class="text-xs text-gray-600">
              Blok A26 & A27, Jl. Raya Pasar Kemis
            </p>
            <p class="text-xs text-gray-600">
              Gelam Jaya, Kecamatan Pasar Kemis
            </p>
            <p class="text-xs text-gray-600">Kab.Tangerang</p>
            <p class="text-xs text-gray-600">Banten 15560</p>
          </div>
        </div>
        <div
          class="mb-10 flex w-full flex-col justify-start md:flex-row lg:mb-0 lg:w-1/4"
        >
          <div class="mb-0 flex flex-col text-gray-600 lg:mb-8">
            <div class="mt-6 flex flex-row">
              <div class="flex cursor-pointer items-center justify-center">
                <InstagramIcon class="h-6" />
              </div>
              <div class="ml-6 flex cursor-pointer items-center justify-center">
                <XIcon class="h-7" />
              </div>
              <div class="ml-6 flex cursor-pointer items-center justify-center">
                <FacebookIcon class="h-8" />
              </div>
              <div class="ml-6 flex cursor-pointer items-center justify-center">
                <WhatsappIcon class="h-6" />
              </div>
            </div>
            <div class="mt-6 flex cursor-pointer md:mt-4">
              <p class="text-xs text-gray-600 hover:text-gray-500">
                admin@gromura.com
              </p>
            </div>
            <div class="mt-2 flex cursor-pointer md:mt-4">
              <p class="text-xs text-gray-600 hover:text-gray-500">
                +6287875475983
              </p>
            </div>
          </div>
        </div>
        <div
          class="mb-8 flex w-full flex-col justify-start md:flex-row md:justify-between lg:mb-0 lg:w-2/4"
        >
          <div class="mb-8 flex flex-col text-gray-600 lg:mb-0">
            <div class="flex cursor-pointer">
              <p class="text-xs text-gray-600 hover:text-gray-500">Beranda</p>
            </div>
            <div class="mt-2 flex cursor-pointer md:mt-4">
              <a href="#our-client">
                <p class="text-xs text-gray-600 hover:text-gray-500">Klien</p>
              </a>
            </div>
          </div>
          <div class="mb-8 flex flex-col text-gray-600 lg:mb-0">
            <div
              class="flex cursor-pointer"
              @click="openRoute('about-us-our-team')"
            >
              <p class="text-xs text-gray-600 hover:text-gray-500">
                Visi & Misi Kami
              </p>
            </div>
            <div
              class="mt-2 flex cursor-pointer md:mt-4"
              @click="openRoute('about-us-our-vision-mission')"
            >
              <p class="text-xs text-gray-600 hover:text-gray-500">Tim Kami</p>
            </div>
            <div class="mt-2 flex cursor-pointer md:mt-4">
              <p class="text-xs text-gray-600 hover:text-gray-500">
                Kontak Kami
              </p>
            </div>
          </div>
          <div class="flex flex-col text-gray-600 lg:mb-0">
            <div class="flex cursor-pointer">
              <p class="text-xs text-gray-600 hover:text-gray-500">
                Pusat Bantuan
              </p>
            </div>
            <div class="mt-2 flex cursor-pointer md:mt-4">
              <p class="text-xs text-gray-600 hover:text-gray-500">FAQ</p>
            </div>
            <div class="mt-2 flex cursor-pointer md:mt-4">
              <p class="text-xs text-gray-600 hover:text-gray-500">
                Term Of Services
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto md:mt-8">
        <div class="border-b-2 border-gray-100"></div>
      </div>
      <p class="my-4 text-center text-xs text-gray-900 md:my-8 md:text-xs">
        &copy;{{ new Date().getFullYear() }} Gromura &nbsp|&nbsp All rights
        reserved &nbsp|&nbsp #gromura #grosirmurah
      </p>
    </div>

    <div
      class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      aria-hidden="true"
    >
      <div
        class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#13cdc1] to-cyan-300 opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        style="
          clip-path: polygon(
            74.1% 44.1%,
            100% 61.6%,
            97.5% 26.9%,
            85.5% 0.1%,
            80.7% 2%,
            72.5% 32.5%,
            60.2% 62.4%,
            52.4% 68.1%,
            47.5% 58.3%,
            45.2% 34.5%,
            27.5% 76.7%,
            0.1% 64.9%,
            17.9% 100%,
            27.6% 76.8%,
            76.1% 97.7%,
            74.1% 44.1%
          );
        "
      ></div>
    </div>
  </footer>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import FooterIllustration from '@/assets/svg/footer.svg'
// import LogoIcon from '@/assets/svg/logo.svg'
import InstagramIcon from '@/assets/svg/socmed/instagram.svg'
import TwitterIcon from '@/assets/svg/socmed/twitter.svg'
import XIcon from '@/assets/svg/socmed/x.svg'
import FacebookIcon from '@/assets/svg/socmed/facebook.svg'
import WhatsappIcon from '@/assets/svg/socmed/whatsapp.svg'

export default {
  name: 'FooterDefault',
  components: {
    FooterIllustration,
    // LogoIcon,
    InstagramIcon,
    TwitterIcon,
    XIcon,
    FacebookIcon,
    WhatsappIcon,
  },
  mixins: [globalMixin],
  data: () => ({ showMenu: false, serviceOpen: false, aboutOpen: false }),
  methods: {},
}
</script>
