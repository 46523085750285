import axios from 'axios'

let baseURL = ''
if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'staging' ||
  import.meta.env.MODE === 'development'
) {
  baseURL = import.meta.env.VITE_API_ORDER_URL
}

export function routePathOrderAPI(version = null) {
  let apiVersion = import.meta.env.VITE_API_ORDER_VERSION
  if (version !== null) apiVersion = version

  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging' ||
    import.meta.env.MODE === 'development'
  ) {
    return `/${apiVersion}/`
  } else {
    return `/api-order/${apiVersion}/`
  }
}

const axiosOrder = axios.create({
  baseURL: baseURL,
  timeout: import.meta.env.VITE_API_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': import.meta.env.VITE_API_ORDER_KEY,
    'x-language-code': import.meta.env.VITE_LANGUAGE_CODE,
  },
})

const guestId = localStorage.getItem('gromura.guestId') || null
if (guestId !== null) {
  axiosOrder.defaults.headers.common['x-guest-id'] = `${guestId}`
} else {
  delete axiosOrder.defaults.headers.common['x-guest-id']
}

const tokenType = localStorage.getItem('gromura.tokenType') || 'Bearer'
const accessToken = localStorage.getItem('gromura.accessToken') || null
if (accessToken !== null) {
  axiosOrder.defaults.headers.common[
    'Authorization'
  ] = `${tokenType} ${accessToken}`
  delete axiosOrder.defaults.headers.common['x-guest-id']
} else {
  delete axiosOrder.defaults.headers.common['Authorization']
}

axiosOrder.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response, config } = error

    if (typeof response !== 'undefined' && response.hasOwnProperty('status')) {
      if (response.status === 401) {
        const location = window.location.pathname.replace(/\//gi, ' ').trim()
        const uri = location.split(' ')
        const instituteId = uri.length > 0 ? uri[0] : null
        if (instituteId !== null) {
          return axiosOrder
            .get(`${routePathOrderAPI()}fetch?instituteId=${instituteId}`, {
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'x-api-key': import.meta.env.VITE_API_ORDER_KEY,
                'x-language-code': import.meta.env.VITE_LANGUAGE_CODE,
              },
            })
            .then((response) => {
              const responseData = response.data
              if (responseData.statusCode === 200) {
                const token = responseData.data

                // SET NEW TOKEN
                localStorage.setItem('gromura.guestId', token.g_id)
                // APPEND TOKEN TO CURRENT HEADER BEARER
                axiosOrder.defaults.headers.common[
                  'x-guest-id'
                ] = `${token.g_id}`

                // RETRYING REQUEST
                return axiosOrder(config)
              } else {
                return Promise.reject(response)
              }
            })
            .catch(() => {
              return Promise.reject(error)
            })
        }
      }
    }

    return Promise.reject(error)
    // throw error
  }
)

export default axiosOrder
