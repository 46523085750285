export const colors = {
  primary: '#12ccbf',
  secondary: '#ffa500',
  success: '#22c55e',
  danger: '#F46D6D',

  warning: '#f59e0b',
  dark: '#0f172a',
  light: '#ffffff',
}
