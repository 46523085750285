<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 py-10 lg:mx-auto lg:px-6 lg:py-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          Gudang Kami
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Kami saat ini baru memiliki satu gudang. Tunggu kehadiran kami di kota
          - kotamu!
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-32"></div>
      </div>
    </div>

    <div class="flex w-full flex-col bg-gray-100">
      <div class="relative flex bg-gray-100 py-20">
        <LinesDotted class="absolute w-full" />
        <div
          class="container z-20 flex w-full flex-col px-6 md:px-0 lg:mx-auto"
        >
          <div class="flex w-full flex-col justify-center">
            <div
              class="flex h-32 w-full flex-col rounded-tl-xl rounded-tr-xl bg-white py-4"
            >
              <div class="flex items-center justify-center border-b-2 pb-2">
                <p class="text-md ml-2 font-black">Gudang - Pasar Kemis</p>
              </div>
              <div class="mt-2 justify-center px-4 text-center">
                <p class="text-sm text-gray-500">
                  Ruko Kutajaya, Blok A26 & A27, Jl. Raya Pasar Kemis Gelam
                  Jaya, Kecamatan Pasar Kemis, Kab.Tangerang, Banten 15560
                </p>
              </div>
            </div>
            <div class="flex w-full justify-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15866.664224710375!2d106.5656563!3d-6.1754419!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ff69da32f3d5%3A0xb236c89157a42f18!2sGromura%20Retail%20-%20Gudang%20Ps.Kemis!5e0!3m2!1sid!2sid!4v1705413319974!5m2!1sid!2sid"
                height="450"
                style="border: 0"
                allowfullscreen="true"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                class="max-h-min w-full rounded-bl-xl rounded-br-xl"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferUser from '@/assets/svg/offers/user.svg'
import CircleCheck from '@/assets/svg/circle-check.svg'
import LinesDotted from '@/assets/svg/lines-dotted.svg'
import MemberShipBronze from '@/assets/svg/membership/bronze.svg'
import MemberShipSilver from '@/assets/svg/membership/silver.svg'
import MemberShipGold from '@/assets/svg/membership/gold.svg'

export default {
  name: 'ServiceSolutionForSupplyChainPage',
  components: {
    CardTwo,
    CardThree,
    OfferUser,
    CircleCheck,
    LinesDotted,
    MemberShipBronze,
    MemberShipSilver,
    MemberShipGold,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Actionable Real Time Information',
        description:
          'The Traditional channel consisting of hundreds of thousands of small stores is by far your single largest channel yet its extremely difficult to obtain actionable and timely supply chain information from it. Gromura’s solutions for Supply Chain gives you real-time sales and inventory data figures to track daily sales, in-store availability and overall channel effectiveness by distribution channel whether direct or indirect. Make your demand planning, distribution planning and in-store availability far more effective by using our unique historical and predictive analytical data and toolset.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'The Gromura Advantage',
        description:
          'We collect and track real-time sales data from tens of thousands of traditional retailers and make this information available to you to consume in real-time either through links to your existing analytics solutions or using our insight and analytics workbench. Our unique platform allows you not only to analyze past data but connect in real-time with small retailers to conduct real-time experiments, surveys, and sentiment analysis. Obtain the feedback and information you need in real-time to make your marketing much more effective and generate a much higher return on marketing spend.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Supply Chain Leader',
        description:
          'Monitor the effectiveness of your supply chain down to the crucial ‘last mile’ of the traditional channel.  use the data from Gromura to more effectively plan and manage your Supply Chain activities to improve service levels at the store level and to lower costs.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'S&OP Leader',
        description:
          'Use the data from the Gromura platform to make your S&OP planning much more effective by utilizing the trend and actual data in the Gromura platform to more effectively plan supply/demand decisions, promotional campaigns, and new product launches.',
        icon: 'USER',
      },
      {
        id: 3,
        title: 'Distribution Manager',
        description:
          'Gromura’s inventory and sales velocity data can be used to more effectively plan and manage your distribution channel and assets to ensure the highest service levels at the lowest cost.  Effectively manage your channel partners or view new direct delivery options by using our real time data to plan, simulate and act effectively.',
        icon: 'USER',
      },
      {
        id: 4,
        title: 'Transportation Manager',
        description:
          'Incorporate our data into your transportation planning to better prioritize and schedule routes, take advantage of reverse logistics opportunities and better manage returnable packaging transportation.',
        icon: 'USER',
      },
    ],
  }),
  methods: {},
}
</script>
