import axios, { routePathAPIVersion } from '@/plugins/axios.js'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  categoryList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = {}
      params.page = payload.page ? payload.page : 1
      params.q = payload.q ? payload.q : ''
      params.take = payload.take ? payload.take : 50

      axios
        .get(`${routePathAPIVersion()}categories/${payload.tenant_id}`, {
          payload,
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  categoryDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPIVersion()}categories/detail/${payload.id}`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_get_data',
              data: responseData.data,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_get_data',
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
