export default {
  language: 'Languages',
  appName: 'gromura',
  greeting: 'Hello world!',
  hello: 'Hello',
  location: 'Jakarta',
  home: 'Home',
}

// export default en
