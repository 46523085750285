<template>
  <section id="content" class="h-full">
    <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          class="mx-auto h-10 w-auto"
          src="@/assets/image/logo.png"
          alt="GasBro"
        />
        <h2
          class="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900"
        >
          Satu langkah untuk masuk ke akunmu
        </h2>
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form class="space-y-6" action="#" method="POST">
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Alamat Email</label
            >
            <div class="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required
                placeholder="Masukan email anda"
                class="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-300 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div class="flex items-center justify-between">
              <label
                for="password"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Kata Sandi</label
              >
              <div class="text-sm">
                <a
                  href="#"
                  class="font-semibold from-[#13cdc1] to-cyan-300"
                  >Lupa kata sandi?</a
                >
              </div>
            </div>
            <div class="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                placeholder="Masukan kata sandi"
                class="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-300 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="flex w-full justify-center rounded-md bg-gradient-to-br from-[#13cdc1] to-cyan-400 px-3 py-3.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-teal-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-300"
            >
              Masuk
            </button>
          </div>
        </form>

        <p class="mt-10 text-center text-sm text-gray-600">
          Belum jadi member?
          <a
            class="cursor-pointer font-semibold leading-6 text-gromura-primary hover:text-teal-600"
            >Gabung bersama kami</a
          >
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import * as Yup from 'yup'
import globalMixin from '@/mixins/global.js'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default {
  name: 'ContactUsPage',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  mixins: [globalMixin],
}
</script>
