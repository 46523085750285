<template>
  <div class="flex flex-row" :class="wrapClass" @click="$emit('copy')">
    <div v-if="prefixText !== ''" class="mr-1">
      <span :class="textPrefixClass"> {{ prefixText }}</span>
    </div>

    <i18n-n
      v-if="false"
      :value="parseInt(totalInString)"
      :format="{ key: 'currencyNoCents', currency: 'IDR' }"
    >
      <template #currency="slotProps">
        <span v-if="withPrefix">{{ slotProps.currency }}</span>
        <span v-else></span>
      </template>
      <template #integer="slotProps">
        <span :class="textClass">{{ slotProps.integer }}</span>
      </template>
    </i18n-n>

    <p class="inline" :class="textClass">
      <span v-html="formatPrice(totalInString, withPrefix)"></span>
    </p>
    <div v-if="leadingText !== ''" class="ml-1">
      <span :class="textLeadingClass"> {{ leadingText }}</span>
    </div>
    <button
      v-if="copyText !== '' && showCopy"
      v-clipboard:copy="copyText"
      class="ml-2 inline rounded-lg text-xs font-semibold"
      :title="$t('copy_to_clipboard')"
      type="button"
      @click="$emit('copy')"
    >
      <CopyIcon class="inline w-4" />
    </button>
  </div>
</template>

<script>
import CopyIcon from '@/assets/svg/copy.svg'

export default {
  name: 'CurrencyComponent',
  components: {
    CopyIcon,
  },
  props: {
    totalInString: {
      type: String,
      default: '0',
      required: true,
    },
    withPrefix: {
      type: Boolean,
      default: true,
    },
    showCopy: {
      type: Boolean,
      default: true,
    },
    wrapClass: {
      type: String,
      default: '',
      required: false,
    },
    textClass: {
      type: String,
      default: '',
      required: false,
    },
    leadingText: {
      type: String,
      default: '',
      required: false,
    },
    textLeadingClass: {
      type: String,
      default: '',
      required: false,
    },
    prefixText: {
      type: String,
      default: '',
      required: false,
    },
    textPrefixClass: {
      type: String,
      default: '',
      required: false,
    },
    copyText: {
      type: String,
      default: '',
      required: false,
    },
    withColorDigits: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['copy'],
  data: () => ({}),
  methods: {
    formatPrice(value, withPrefix) {
      const values = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      let html = ''
      if (withPrefix) {
        html += `<span>Rp</span>`
      }

      if (!this.withColorDigits) {
        html += `<span>${values}</span>`
        return html
      } else {
        const nums = values.split('')
        let arrLen = nums.length
        let arrIndex = [arrLen - 1, arrLen - 2, arrLen - 3]
        nums.forEach(function (n, index) {
          if (arrIndex.includes(index)) {
            html += `<span class=text-darkorange>${n}</span>`
          } else {
            html += `<span>${n}</span>`
          }
        })
        return html
      }
    },
  },
}
</script>
