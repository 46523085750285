<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          OUR SERVICES
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Solutions for Marketing
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Form better connections to reach customers and consumers needs faster,
          more
        </p>
        <p class="mt-2 text-xs text-gray-400">
          effectively and achieve much higher marketing return on investment
        </p>
      </div>

      <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img src="@/assets/image/home-1.png" class="w-full rounded-xl" />
        </div>
      </div>

      <div class="flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informations" :key="item.id" class="flex w-full">
          <CardThree :title="item.title" :description="item.description" />
        </div>
      </div>

      <!-- Offers -->
      <div class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:pb-16">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            WHAT WE OFFER
          </p>
        </div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col flex-wrap px-6 md:flex-row lg:px-32">
        <div
          v-for="item in offers"
          :key="item.id"
          class="flex w-full p-2 md:w-1/3 lg:p-8"
        >
          <CardTwo :title="item.title" :description="item.description">
            <OfferPricing v-if="item.icon === 'PRICING'" class="h-10" />
            <OfferUser v-if="item.icon === 'USER'" class="h-10" />
            <OfferStore v-if="item.icon === 'STORE'" class="h-10" />
            <OfferSearch v-if="item.icon === 'SEARCH'" class="h-10" />
          </CardTwo>
        </div>
      </div>

      <div class="mt-20 flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informationsTwo" :key="item.id" class="flex w-full">
          <CardThree
            :title="item.title"
            :description="item.description"
            :show-icon="false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferPricing from '@/assets/svg/offers/price-coin.svg'
import OfferUser from '@/assets/svg/offers/user.svg'
import OfferStore from '@/assets/svg/offers/store.svg'
import OfferSearch from '@/assets/svg/offers/search.svg'

export default {
  name: 'ServiceSolutionForMarketingPage',
  components: {
    CardTwo,
    CardThree,
    OfferPricing,
    OfferUser,
    OfferStore,
    OfferSearch,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Elevate Your Marketing',
        description:
          'Despite the importance of the traditional channel, it has been extremely difficult to obtain - until now!  Gromura’s marketing solutions allow you to gain real-time information to improve your marketing initiatives and obtain a much higher Return on Marketing investments.',
      },
      {
        id: 2,
        title: 'Actionable Real Time Information',
        description:
          'The Traditional channel consisting of hundreds of thousands of small stores is by far your single largest channel yet its extremely difficult to obtain actionable and timely marketing information from it. Gromura’s marketing solutions allow you to gain real-time information to improve your marketing initiatives and obtain a much higher Return on Marketing investments.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'The Gromura Advantage',
        description:
          'We collect and track real-time sales data from tens of thousands of traditional retailers and make this information available to you to consume in real-time either through links to your existing analytics solutions or using our insight and analytics workbench. Our unique platform allows you not only to analyze past data but connect in real-time with small retailers to conduct real-time experiments, surveys, and sentiment analysis. Obtain the feedback and information you need in real-time to make your marketing much more effective and generate a much higher return on marketing spend.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Executive Management',
        description:
          'Obtain a holistic view of your organization’s performance across this key channel.  View brand performance and market share trends, product reach and proliferation and much more to help you make the most crucial decisions.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'Pricing Strategist',
        description:
          'Utilize advanced analytics on pricing variations in micro geographies and quickly see correlations and trends between price and sales.  Use the data to develop advanced pricing strategies, quickly respond to competitive price changes and use the leverage of the platform to perform advanced pricing optimization.',
        icon: 'PRICING',
      },
      {
        id: 3,
        title: 'Campaign Manager',
        description:
          'Make your promotional, new product and advertising campaigns much more effective with real-time feedback on retail execution and effectiveness.  Utilize the power of the Gromura platform to conduct effective and profitable micro-campaigns and research.',
        icon: 'PRICING',
      },
      {
        id: 4,
        title: 'Brand / Category Manager',
        description:
          'Track brand performance across multiple axes including market share/share of wallet by geography and demographics.  Monitor your campaigns’ effectiveness in real-time and quickly see and react when the competition moves in the market.',
        icon: 'STORE',
      },
      {
        id: 5,
        title: 'Market Research Manager',
        description:
          'Turbocharge your market research and customer sentiment analysis by utilizing the full power of the Gromura platform to conduct precision research and obtain near real-time insights.',
        icon: 'SEARCH',
      },
      {
        id: 6,
        title: 'Chief Marketing Officer',
        description:
          'Track your marketing performance and obtain real-time data on your most important KPIs such as ROI and campaigns, New Product Introductions and Pricing.',
        icon: 'USER',
      },
    ],
  }),
  methods: {},
}
</script>
