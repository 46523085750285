<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div
        class="mb-10 flex w-full flex-col items-center text-center md:mb-20 lg:mb-32"
      >
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          AYO BERTEMU
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Tim Luar Biasa Kami
        </p>

        <p class="mt-4 text-xs text-gray-400 lg:text-sm">
          Berkenalan dengan orang-orang yang membantu menciptakan Gromura
          sebagai sebuah perusahaan
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-40"></div>
      </div>

      <div class="flex w-full flex-col flex-wrap md:flex-row md:px-6 lg:px-32">
        <div
          v-for="item in team"
          :key="item.id"
          class="flex w-full p-2 md:w-1/2"
        >
          <CardFour :title="item.name" :subtitle="item.position">
            <img
              v-if="item.avatar === 'rinaldi'"
              src="@/assets/image/team/rinaldi.png"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'idin'"
              src="@/assets/image/team/idin.png"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'rubby'"
              src="@/assets/image/team/rubby.png"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'faran'"
              src="@/assets/image/team/faransyah.jpg"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'furqon'"
              src="@/assets/image/team/furqon.jpeg"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'stefanus'"
              src="@/assets/image/team/stefanus.jpeg"
              class="w-24 rounded-lg"
            />
          </CardFour>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardFour from '@/components/card/CardFour.vue'

export default {
  name: 'ServiceSolutionForMarketingPage',
  components: {
    CardFour,
  },
  mixins: [globalMixin],
  data: () => ({
    team: [
      {
        id: 1,
        name: 'Rinaldi Saputra',
        position: 'Founder & Komisaris',
        avatar: 'rinaldi',
      },
      {
        id: 2,
        name: 'Faransyah Jaya',
        position: 'Chief Executive Officer',
        avatar: 'faran',
      },
      {
        id: 3,
        name: 'Ahmad Muhyidin',
        position: 'Chief Technology Officer',
        avatar: 'idin',
      },
      {
        id: 4,
        name: 'Rubby Maulana Christy',
        position: 'Chief Sales Officer',
        avatar: 'rubby',
      },
      {
        id: 5,
        name: 'Abdul Furqon',
        position: 'Chief Operational Officer',
        avatar: 'furqon',
      },
      {
        id: 6,
        name: 'Stefanus',
        position: 'Chief Finance Officer',
        avatar: 'stefanus',
      },
    ],
  }),
  methods: {},
}
</script>
