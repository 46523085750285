import axios, { routePathAPI } from '@/plugins/axios.js'

// initial state
const state = () => ({ activeUserId: '', needVerifyStatusEdit: false })

// getters
const getters = {
  activeUserIdGetter(state) {
    return state.activeUserId || ''
  },
  needVerifyStatusEditGetter(state) {
    return state.needVerifyStatusEdit
  },
}

// actions
const actions = {
  setActiveUserId({ commit }, payload) {
    commit('SET_ACTIVE_USER', payload)
  },
  needVerifyStatusEdit({ commit, state }, status) {
    return commit('SET_NEED_VERIFY_STATUS_EDIT', status)
  },
  profile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}profile`)
        .then((response) => {
          const responseData = response.data.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  point({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}auth/users/point`)
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  balance({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}auth/users/balance`)
        .then((response) => {
          const responseData = response.data
          resolve({
            status: response.status,
            message: responseData.message,
            data: responseData,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateProfile({ state, commit }, formData) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}profile/`, formData, config)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success_update_profile',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed_update_profile',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('gromura.userInfo')) ||
      state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('gromura.userInfo', JSON.stringify(userInfo))
  },
  SET_ACTIVE_USER(state, payload) {
    state.activeUserId = payload
  },
  SET_NEED_VERIFY_STATUS_EDIT(state, status) {
    state.needVerifyStatusEdit = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
